import { ImpactStyle } from "@capacitor/haptics";
import * as React from "react";
import { matchPath } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { hapticsImpact } from "~/config/capacitor/haptics/hook";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Column, Columns } from "~/design-system/Columns";
import { useDrawerContext } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { ProjectBadge } from "~/screens/App/components/NotificationsBadge/ProjectBadge";
import { ProjectOrgCategories } from "~/screens/App/screens/Dashboard/screens/Home/ProjectsListScreen/components/ProjectsList/components/ProjectOrgCategories";
import type { ProjectListMenuProps } from "../../ProjectListMenu";

const PROJECT_ITEM_HEIGHT = 76;

type ProjectItemProps = {
  data: ProjectListMenuProps["projects"][number];
  active?: boolean;
};

const ProjectItem = React.memo(function ProjectItem(props: ProjectItemProps) {
  const { setOpen } = useDrawerContext();
  const location = useLocation();
  const matchedParams = matchPath(
    "/project/:projectId/:projectSection/*",
    location.pathname
  );
  // Extract current project section to redirect to the proper sections on project change
  const projectSection = React.useMemo(
    () => matchedParams?.params.projectSection,
    [matchedParams]
  );

  const navigateTo = React.useMemo(
    () =>
      projectSection
        ? `/project/${props.data.id}/${projectSection}`
        : `/project/${props.data.id}`,
    [projectSection, props.data.id]
  );

  const handleClick = React.useCallback(async () => {
    setOpen(false);
    await hapticsImpact(ImpactStyle.Light);
  }, [setOpen]);

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingY="xsmall"
      paddingX="small"
      height="full"
      css={{
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
        "&:mobile": {
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
        "&:tablet": {
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
        backgroundColor: props.active
          ? "rgba(255, 255, 255, 0.1)"
          : "transparent",
        paddingRight: "1.25rem",
      }}
      /* TODO add route to project page from projectId as /project/:projectId/ */
      as={Link}
      to={navigateTo}
      onClick={handleClick}
    >
      <Columns space="small" alignY="center">
        <Column width="content">
          {props.data?.project_avatar?.public_url ? (
            <Avatar
              alt={`${props.data.name} avatar`}
              src={props.data?.project_avatar?.public_url}
              shape="rounded"
              size="large"
              loading="lazy"
              ring={props.active ? "lightWhite" : undefined}
            />
          ) : (
            <Avatar
              size="large"
              shape="rounded"
              ring={props.active ? "lightWhite" : undefined}
            >
              {props.data.name}
            </Avatar>
          )}
        </Column>
        <Column>
          <Box width="full">
            <Stack space="xxsmall">
              <Box display="flex">
                <Text truncate={true}>{props.data.name}</Text>
              </Box>
              <ProjectOrgCategories
                maxNumberOfBadges={1}
                projectId={props.data.id}
                disableOnClick
                isDemo={props.data.is_demo}
                data={props.data}
              />
            </Stack>
          </Box>
        </Column>
        <Column width="content" align="right">
          {!props.active && <ProjectBadge projectId={props.data.id} />}
        </Column>
      </Columns>
    </Box>
  );
});

export { PROJECT_ITEM_HEIGHT, ProjectItem };
