import type * as Types from '../../../../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateOrgProjectSummaryForm_Project_CategoriesFragment = { id: string, color: string, name: string };

export type UpdateOrgProjectSummaryForm_Org_Project_SummaryFragment = { id: string, org_id: string, reference?: string | null | undefined, start_date?: string | null | undefined, end_date?: string | null | undefined, client_fullname?: string | null | undefined, client_email?: string | null | undefined, client_phone_number?: string | null | undefined, price_estimate?: string | null | undefined, hours_expected?: number | null | undefined, hours_done?: number | null | undefined, project_id: string, project_categories: Array<{ project_category: { id: string, color: string, name: string } }>, project_groups: Array<{ project_group: { id: string, color: string, name: string, project_group_avatar?: { public_url: string } | null | undefined } }> };

export type UpdateOrgProjectSummaryForm_Project_GroupsFragment = { id: string, color: string, name: string, project_group_avatar?: { public_url: string } | null | undefined };

export type GetOrgProjectSummaryQueryVariables = Types.Exact<{
  orgProjectSummaryId: Types.Scalars['uuid'];
}>;


export type GetOrgProjectSummaryQuery = { org_project_summary_by_pk?: { id: string, org_id: string, reference?: string | null | undefined, start_date?: string | null | undefined, end_date?: string | null | undefined, client_fullname?: string | null | undefined, client_email?: string | null | undefined, client_phone_number?: string | null | undefined, price_estimate?: string | null | undefined, hours_expected?: number | null | undefined, hours_done?: number | null | undefined, project_id: string, project_categories: Array<{ project_category: { id: string, color: string, name: string } }>, project_groups: Array<{ project_group: { id: string, color: string, name: string, project_group_avatar?: { public_url: string } | null | undefined } }> } | null | undefined };

export type GetUpdateOrgProjectSummaryCategoriesOptionsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetUpdateOrgProjectSummaryCategoriesOptionsQuery = { project_categories: Array<{ id: string, color: string, name: string }> };

export type GetUpdateOrgProjectSummaryGroupsOptionsQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetUpdateOrgProjectSummaryGroupsOptionsQuery = { project_groups: Array<{ id: string, color: string, name: string, project_group_avatar?: { public_url: string } | null | undefined }> };

export type UpdateOrgProjectSummaryMutationVariables = Types.Exact<{
  input: Types.UpdateOrgProjectSummaryInput;
}>;


export type UpdateOrgProjectSummaryMutation = { updateOrgProjectSummary?: { __typename: 'UpdateOrgProjectSummaryErrors' } | { __typename: 'UpdateOrgProjectSummarySuccess', org_project_summary_id: string } | null | undefined };

export type CreateProjectCategoryMutationVariables = Types.Exact<{
  input: Types.CreateProjectCategoryInput;
}>;


export type CreateProjectCategoryMutation = { createProjectCategory?: { __typename: 'CreateProjectCategoryErrors' } | { __typename: 'CreateProjectCategorySuccess', project_category_id: string } | null | undefined };

export type CreateProjectGroupMutationVariables = Types.Exact<{
  input: Types.CreateProjectGroupInput;
}>;


export type CreateProjectGroupMutation = { createProjectGroup?: { __typename: 'CreateProjectGroupErrors' } | { __typename: 'CreateProjectGroupSuccess', project_group_id: string } | null | undefined };

export type ProjectClientsFragment = { org_project_summary?: Array<{ client_fullname?: string | null | undefined }> | null | undefined };

export type GetProjectClientsFormOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProjectClientsFormOptionsQuery = { projects: Array<{ org_project_summary?: Array<{ client_fullname?: string | null | undefined }> | null | undefined }>, projects_map_overview: Array<{ org_project_summary?: Array<{ client_fullname?: string | null | undefined }> | null | undefined }> };

export const UpdateOrgProjectSummaryForm_Project_CategoriesFragmentDoc = gql`
    fragment UpdateOrgProjectSummaryForm_project_categories on project_categories {
  id
  color
  name
}
    `;
export const UpdateOrgProjectSummaryForm_Project_GroupsFragmentDoc = gql`
    fragment UpdateOrgProjectSummaryForm_project_groups on project_groups {
  id
  color
  name
  project_group_avatar {
    public_url
  }
}
    `;
export const UpdateOrgProjectSummaryForm_Org_Project_SummaryFragmentDoc = gql`
    fragment UpdateOrgProjectSummaryForm_org_project_summary on org_project_summary {
  id
  org_id
  project_categories(order_by: {order: asc_nulls_first}) {
    project_category {
      ...UpdateOrgProjectSummaryForm_project_categories
    }
  }
  project_groups(order_by: {order: asc_nulls_first}) {
    project_group {
      ...UpdateOrgProjectSummaryForm_project_groups
    }
  }
  reference
  start_date
  end_date
  client_fullname
  client_email
  client_phone_number
  price_estimate
  hours_expected
  hours_done
  project_id
}
    ${UpdateOrgProjectSummaryForm_Project_CategoriesFragmentDoc}
${UpdateOrgProjectSummaryForm_Project_GroupsFragmentDoc}`;
export const ProjectClientsFragmentDoc = gql`
    fragment projectClients on projects {
  org_project_summary {
    client_fullname
  }
}
    `;
export const GetOrgProjectSummaryDocument = gql`
    query getOrgProjectSummary($orgProjectSummaryId: uuid!) {
  org_project_summary_by_pk(id: $orgProjectSummaryId) {
    ...UpdateOrgProjectSummaryForm_org_project_summary
  }
}
    ${UpdateOrgProjectSummaryForm_Org_Project_SummaryFragmentDoc}`;

export function useGetOrgProjectSummaryQuery(options: Omit<Urql.UseQueryArgs<GetOrgProjectSummaryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetOrgProjectSummaryQuery>({ query: GetOrgProjectSummaryDocument, ...options });
};
export const GetUpdateOrgProjectSummaryCategoriesOptionsDocument = gql`
    query getUpdateOrgProjectSummaryCategoriesOptions($orgId: uuid!) {
  project_categories(where: {org_id: {_eq: $orgId}}) {
    ...UpdateOrgProjectSummaryForm_project_categories
  }
}
    ${UpdateOrgProjectSummaryForm_Project_CategoriesFragmentDoc}`;

export function useGetUpdateOrgProjectSummaryCategoriesOptionsQuery(options: Omit<Urql.UseQueryArgs<GetUpdateOrgProjectSummaryCategoriesOptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetUpdateOrgProjectSummaryCategoriesOptionsQuery>({ query: GetUpdateOrgProjectSummaryCategoriesOptionsDocument, ...options });
};
export const GetUpdateOrgProjectSummaryGroupsOptionsDocument = gql`
    query getUpdateOrgProjectSummaryGroupsOptions($orgId: uuid!) {
  project_groups(where: {org_id: {_eq: $orgId}}) {
    ...UpdateOrgProjectSummaryForm_project_groups
  }
}
    ${UpdateOrgProjectSummaryForm_Project_GroupsFragmentDoc}`;

export function useGetUpdateOrgProjectSummaryGroupsOptionsQuery(options: Omit<Urql.UseQueryArgs<GetUpdateOrgProjectSummaryGroupsOptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetUpdateOrgProjectSummaryGroupsOptionsQuery>({ query: GetUpdateOrgProjectSummaryGroupsOptionsDocument, ...options });
};
export const UpdateOrgProjectSummaryDocument = gql`
    mutation updateOrgProjectSummary($input: UpdateOrgProjectSummaryInput!) {
  updateOrgProjectSummary(input: $input) {
    __typename
    ... on UpdateOrgProjectSummarySuccess {
      org_project_summary_id
    }
  }
}
    `;

export function useUpdateOrgProjectSummaryMutation() {
  return Urql.useMutation<UpdateOrgProjectSummaryMutation, UpdateOrgProjectSummaryMutationVariables>(UpdateOrgProjectSummaryDocument);
};
export const CreateProjectCategoryDocument = gql`
    mutation createProjectCategory($input: CreateProjectCategoryInput!) {
  createProjectCategory(input: $input) {
    __typename
    ... on CreateProjectCategorySuccess {
      project_category_id
    }
  }
}
    `;

export function useCreateProjectCategoryMutation() {
  return Urql.useMutation<CreateProjectCategoryMutation, CreateProjectCategoryMutationVariables>(CreateProjectCategoryDocument);
};
export const CreateProjectGroupDocument = gql`
    mutation createProjectGroup($input: CreateProjectGroupInput!) {
  createProjectGroup(input: $input) {
    __typename
    ... on CreateProjectGroupSuccess {
      project_group_id
    }
  }
}
    `;

export function useCreateProjectGroupMutation() {
  return Urql.useMutation<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>(CreateProjectGroupDocument);
};
export const GetProjectClientsFormOptionsDocument = gql`
    query getProjectClientsFormOptions {
  projects {
    ...projectClients
  }
  projects_map_overview {
    org_project_summary {
      client_fullname
    }
  }
}
    ${ProjectClientsFragmentDoc}`;

export function useGetProjectClientsFormOptionsQuery(options: Omit<Urql.UseQueryArgs<GetProjectClientsFormOptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectClientsFormOptionsQuery>({ query: GetProjectClientsFormOptionsDocument, ...options });
};