import * as React from "react";
import { Box } from "~/design-system/Box";
import { DrawerContent } from "~/design-system/Drawer";
import { Stack } from "~/design-system/Stack";
import { DrawerHeader } from "./components/DrawerHeader";
import type { UpdateOrgProjectSummaryFormProps } from "./components/UpdateOrgProjectSummaryForm/UpdateOrgProjectSummaryForm";
import { UpdateOrgProjectSummaryForm } from "./components/UpdateOrgProjectSummaryForm/UpdateOrgProjectSummaryForm";
import { useGetOrgProjectSummaryIdQuery } from "./UpdateOrgProjectSummaryDrawerContent.graphql";

function UpdateOrgProjectSummaryDrawerContent(
  props: UpdateOrgProjectSummaryFormProps
) {
  /**
   * The `pause` prop prevents unnecessary API requests in certain flows.
   *
   * Previously, ProjectOrgCategories would fetch categories per project individually.
   * Now, this data is included in the main projects query for better performance.
   *
   * To maintain backward compatibility with existing implementations where
   * orgProjectSummaryId is undefined, this prop allows us to skip the query
   * in those cases, preserving the original behavior.
   */
  const [{ data }] = useGetOrgProjectSummaryIdQuery({
    pause: props.shouldPauseOrgQuery,
    variables: { orgProjectSummaryId: props.orgProjectSummaryId },
  });

  return (
    <DrawerContent
      position="right"
      css={{
        background:
          "linear-gradient($emerald500 0%, $emerald500 10%, $white 10%)",
      }}
    >
      <Stack>
        <DrawerHeader project={data?.org_project_summary_by_pk?.project} />
        <Box padding="gutter" background="card">
          <UpdateOrgProjectSummaryForm {...props} />
        </Box>
      </Stack>
    </DrawerContent>
  );
}

export { UpdateOrgProjectSummaryDrawerContent };
