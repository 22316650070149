import * as React from "react";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Divider } from "~/design-system/Divider";
import { Heading } from "~/design-system/Heading";
import { Inline } from "~/design-system/Inline";
import { Stack } from "~/design-system/Stack";
import { InfoList } from "./components/InfoList";
import { Toolbar } from "./components/Toolbar";
import type {
  OrgProfileContainerQuery,
  ProjectInfosQuery,
} from "./OrgProfileContainer.graphql";

interface OrgProfileProps {
  org: NonNullable<OrgProfileContainerQuery["orgs_by_pk"]>;
  orgMembersInProject?: NonNullable<
    ProjectInfosQuery["projects_by_pk"]
  >["members"];
  projectName?: NonNullable<ProjectInfosQuery["projects_by_pk"]>["name"];
  handleClose: () => void;
}

export type { OrgProfileProps };
export function OrgProfile(props: OrgProfileProps) {
  return (
    <Box background="card" paddingY="medium" paddingX="gutter">
      <Stack space="small" align="center">
        <Toolbar handleClose={props.handleClose} />
        <Inline space="small">
          <Avatar
            alt={props.org.name}
            src={props.org?.org_avatar?.public_url ?? undefined}
            shape="rounded"
            size="large"
          />
          <Heading level="2">{props.org.name}</Heading>
        </Inline>
        <Divider />
        <InfoList
          org={props.org}
          handleClose={props.handleClose}
          orgMembersInProject={props.orgMembersInProject}
          projectName={props.projectName}
        />
      </Stack>
    </Box>
  );
}
