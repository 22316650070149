import type * as Types from '../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectTeamDrawer_TeamQueryVariables = Types.Exact<{
  teamId: Types.Scalars['uuid'];
}>;


export type ProjectTeamDrawer_TeamQuery = { teams_by_pk?: { name: string, id: string, project: { id: string, name: string }, users: Array<{ user: { id: string, full_name?: string | null | undefined, user_avatar?: { public_url: string } | null | undefined } }> } | null | undefined };


export const ProjectTeamDrawer_TeamDocument = gql`
    query ProjectTeamDrawer_team($teamId: uuid!) {
  teams_by_pk(id: $teamId) {
    name
    id
    project {
      id
      name
    }
    users {
      user {
        id
        user_avatar {
          public_url
        }
        full_name
      }
    }
  }
}
    `;

export function useProjectTeamDrawer_TeamQuery(options: Omit<Urql.UseQueryArgs<ProjectTeamDrawer_TeamQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProjectTeamDrawer_TeamQuery>({ query: ProjectTeamDrawer_TeamDocument, ...options });
};