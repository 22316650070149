import * as React from "react";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { Drawer } from "~/design-system/Drawer";
import { CreateOrgProjectSummaryDrawerContent } from "./components/CreateOrgProjectSummaryDrawerContent/CreateOrgProjectSummaryDrawerContent";
import { UpdateOrgProjectSummaryDrawerContent } from "./components/UpdateOrgProjectSummaryDrawerContent/UpdateOrgProjectSummaryDrawerContent";

type UpsertOrgProjectSummaryDrawerProps = Pick<
  ControlledDrawerProps,
  "open" | "setOpen"
> & {
  children?: React.ReactNode;
  onSuccess?: (orgProjectSummaryId: string) => void;
  create?: {
    orgId: string;
    projectId: string;
  };
  update?: {
    orgProjectSummaryId: string;
    orgId: string;
  };
  setOpenCategoryDrawer?: (open: boolean) => void;
  shouldPauseOrgQuery?: boolean;
};

function UpsertOrgProjectSummaryDrawer(
  props: UpsertOrgProjectSummaryDrawerProps
) {
  if (props.create) {
    return (
      <Drawer open={props.open} setOpen={props.setOpen}>
        <CreateOrgProjectSummaryDrawerContent
          orgId={props.create.orgId}
          projectId={props.create.projectId}
          onSuccess={props.onSuccess}
        />
        {props.children}
      </Drawer>
    );
  }
  if (props.update) {
    return (
      <Drawer open={props.open} setOpen={props.setOpen}>
        <UpdateOrgProjectSummaryDrawerContent
          shouldPauseOrgQuery={props.shouldPauseOrgQuery}
          orgId={props.update.orgId}
          orgProjectSummaryId={props.update.orgProjectSummaryId}
          onSuccess={props.onSuccess}
          setOpenCategoryDrawer={props.setOpenCategoryDrawer}
          setOpenProjectSummaryDrawer={props.setOpen}
        />
        {props.children}
      </Drawer>
    );
  }
  return null;
}

export type { UpsertOrgProjectSummaryDrawerProps };
export { UpsertOrgProjectSummaryDrawer };
