import type * as Types from '../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FilesFoldersNavigatorsData_FolderFragment = { id: string, name: string };

export type FilesFoldersNavigatorsData_FileFragment = { id: string, name: string, extension?: string | null | undefined, due_date?: string | null | undefined, last_version?: Array<{ id: string, created_at: string, updated_at: string, size?: number | null | undefined, url?: string | null | undefined, creator: { full_name?: string | null | undefined, user_avatar?: { public_url: string } | null | undefined } }> | null | undefined };

export type GetFilesFoldersNavigatorQueryVariables = Types.Exact<{
  folder_id: Types.Scalars['uuid'];
}>;


export type GetFilesFoldersNavigatorQuery = { folders_by_pk?: { id: string, name: string, folders: Array<{ id: string, name: string }>, files: Array<{ id: string, name: string, extension?: string | null | undefined, due_date?: string | null | undefined, last_version?: Array<{ id: string, created_at: string, updated_at: string, size?: number | null | undefined, url?: string | null | undefined, creator: { full_name?: string | null | undefined, user_avatar?: { public_url: string } | null | undefined } }> | null | undefined }>, pwd?: Array<{ id: string, level: number, name: string }> | null | undefined } | null | undefined };

export type GetProjectFileRootQueryVariables = Types.Exact<{
  project_id: Types.Scalars['uuid'];
}>;


export type GetProjectFileRootQuery = { projects_by_pk?: { root_bin_folder_id?: string | null | undefined, root_folder_id?: string | null | undefined } | null | undefined };

export const FilesFoldersNavigatorsData_FolderFragmentDoc = gql`
    fragment FilesFoldersNavigatorsData_folder on folders {
  id
  name
}
    `;
export const FilesFoldersNavigatorsData_FileFragmentDoc = gql`
    fragment FilesFoldersNavigatorsData_file on files {
  id
  name
  extension
  due_date
  last_version {
    id
    created_at
    updated_at
    size
    url
    creator {
      full_name
      user_avatar {
        public_url
      }
    }
  }
}
    `;
export const GetFilesFoldersNavigatorDocument = gql`
    query GetFilesFoldersNavigator($folder_id: uuid!) {
  folders_by_pk(id: $folder_id) {
    id
    name
    folders {
      ...FilesFoldersNavigatorsData_folder
    }
    files {
      ...FilesFoldersNavigatorsData_file
    }
    pwd(order_by: {level: asc}) {
      id
      level
      name
    }
  }
}
    ${FilesFoldersNavigatorsData_FolderFragmentDoc}
${FilesFoldersNavigatorsData_FileFragmentDoc}`;

export function useGetFilesFoldersNavigatorQuery(options: Omit<Urql.UseQueryArgs<GetFilesFoldersNavigatorQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetFilesFoldersNavigatorQuery>({ query: GetFilesFoldersNavigatorDocument, ...options });
};
export const GetProjectFileRootDocument = gql`
    query GetProjectFileRoot($project_id: uuid!) {
  projects_by_pk(id: $project_id) {
    root_bin_folder_id
    root_folder_id
  }
}
    `;

export function useGetProjectFileRootQuery(options: Omit<Urql.UseQueryArgs<GetProjectFileRootQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectFileRootQuery>({ query: GetProjectFileRootDocument, ...options });
};