import * as React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Text } from "~/design-system/Text";
import { ProjectGroupBadge } from "~/screens/App/components/NotificationsBadge/ProjectGroupBadge";
import type { ProjectListByGroup } from "../ProjectListByGroup";
import type { ProjectNavigationDrawer } from "../ProjectListMenu";

type ProjectListHeaderByGroupProps = {
  projectListByGroup: ProjectListByGroup[];
  onClick: (groupID: string | null) => void;
};

function ProjectListHeaderByGroup(props: ProjectListHeaderByGroupProps) {
  const { t } = useTranslation();

  const getGroupInfo = (
    group: ProjectListByGroup
  ): { groupName: string; groupAvatar?: string; color?: string } => {
    return {
      color: group.color,
      groupAvatar:
        group?.project_group_avatar?.public_url ?? group?.avatar ?? "",
      groupName:
        group?.name ||
        t("NavigarionDrawer.ProjectListMenu.UnnamedGroup", "Unnamed Group"),
    };
  };

  return (
    <>
      {props.projectListByGroup.map((group: ProjectListByGroup, index) => {
        const projectIds: string[] = group.projects.map(
          (project: ProjectNavigationDrawer) => project.id
        );
        const { color, groupAvatar, groupName } = getGroupInfo(group);
        return (
          <Box
            key={index}
            css={{
              "&:focus-within": { boxShadow: "none" },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              color: "White",
              padding: "$xsmall",
            }}
            onClick={() => props.onClick(group.id)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="spaceBetween"
              marginRight="large"
            >
              <Box display="flex" gap="small" alignItems="center">
                {groupAvatar ? (
                  <Avatar
                    alt={`${groupName} avatar`}
                    src={groupAvatar}
                    shape="rounded"
                    size="large"
                    ring={"lightWhite"}
                  />
                ) : (
                  <Box
                    css={{
                      backgroundColor: color,
                      borderRadius: "$2xl",
                      minWidth: "3em",
                      padding: "$small",
                    }}
                  >
                    <Text css={{ color: "white" }} size="large">
                      {groupName[0].toUpperCase()}
                      {index + 1}
                    </Text>
                  </Box>
                )}
                {groupName}
              </Box>
              <ProjectGroupBadge projectsId={projectIds} />
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export { ProjectListHeaderByGroup };
