import * as React from "react";
import { useAbac } from "react-abac";
import { useTranslation } from "react-i18next";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Column, Columns } from "~/design-system/Columns";
import { DialogTrigger } from "~/design-system/Dialog";
import { Inline } from "~/design-system/Inline";
import { Paragraph } from "~/design-system/Paragraph";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { UserBadgeButton } from "~/screens/App/components/Badges";
import { createComponentHook } from "~/types";
import { Permission } from "../../../../Project.authorize";
import { UpdateTeamMembersDialog } from "../../../UpdateTeamMembersDialog/UpdateTeamMembersDialog";
import type { ProjectTeamDrawer_TeamQuery } from "../../ProjectTeamDrawer.graphql";

const useProjectTeamMembers = createComponentHook(() => {
  const { t } = useTranslation();
  const { userHasPermissions } = useAbac();

  return {
    actions: {
      userHasPermissions,
    },
    t,
  };
});

function ProjectTeamMembers(props: {
  members: NonNullable<ProjectTeamDrawer_TeamQuery["teams_by_pk"]>["users"];
  handleClose: () => void;
  projectName: string;
  teamName: string;
  teamId: string;
}) {
  const { actions, t } = useProjectTeamMembers();

  return (
    <Box paddingX="gutter">
      <Stack>
        <Columns alignY="center">
          <Column>
            <Text tone="neutral">
              {t(
                "ProjectTeamDrawer.ProjectTeamMembers.participants",
                "Participants of {{projectName}} project in this team",
                { projectName: props.projectName }
              )}
            </Text>
          </Column>
          <Column width="content">
            <UpdateTeamMembersDialog
              teamId={props.teamId}
              teamName={props.teamName}
            >
              <DialogTrigger asChild>
                <Button
                  link
                  disabled={
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    !actions.userHasPermissions(Permission.UpdateProjectTeam)
                  }
                  dataIntercomTarget="project-teams-edit"
                >
                  {t("ProjectTeamDrawer.ProjectTeamMembers.edit", "edit")}
                </Button>
              </DialogTrigger>
            </UpdateTeamMembersDialog>
          </Column>
        </Columns>
        {props.members.length ? (
          <Box paddingTop="xxsmall">
            <Inline space="small">
              {props.members.map((m) => (
                <UserBadgeButton
                  onCloseDrawer={props.handleClose}
                  size="large"
                  key={m.user.id}
                  userId={m.user.id}
                  avatar={m.user?.user_avatar?.public_url}
                >
                  {m.user.full_name}
                </UserBadgeButton>
              ))}
            </Inline>
          </Box>
        ) : (
          <Paragraph italic>
            {t(
              "ProjectTeamDrawer.ProjectTeamMembers.noMemberYet",
              "There is no user in this team yet."
            )}
          </Paragraph>
        )}
      </Stack>
    </Box>
  );
}

export { ProjectTeamMembers };
