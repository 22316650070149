import type { Color } from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";
import { TaskNumber } from "../../screens/Project/Tasks/screens/ProjectTasksListScreen/components/ProjectTasksList/components/ProjectTasksListDesktop/components/ProjectTasksListItem/TaskNumber";
import type { Validation } from "../../screens/Project/Tasks/types";
import { isTaskClosed } from "../../screens/Project/Tasks/types";

type TaskTypeBadge = {
  number: number;
  is_validated_by: string | null | undefined;
  labels: { label: { color: string } }[];
};

type TaskBadgeProps = Pick<
  BadgeProps,
  "children" | "clickable" | "onRemove" | "shadow" | "size"
> & {
  avatar?: string | null;
  task?: TaskTypeBadge;
};

const TaskBadge = (props: TaskBadgeProps) => {
  const { avatar, ...rest } = props;

  if (!props.task)
    return (
      <Text size="inherit" truncate>
        {props.children}
      </Text>
    );

  return (
    <Badge
      avatar={
        <TaskNumber
          closed={isTaskClosed(props.task.is_validated_by as Validation)}
          isValidatedBy={
            (props.task.is_validated_by as Validation) ?? undefined
          }
          color={props.task.labels[0]?.label.color as Color}
          size="small"
        >
          {props.task.number}
        </TaskNumber>
      }
      shape="circular"
      maxWidth="full"
      {...rest}
      css={{ minWidth: "80%" }}
    >
      <Text size="inherit" truncate>
        {props.children}
      </Text>
    </Badge>
  );
};

export { TaskBadge };
export type { TaskTypeBadge };
