import {
  Color,
  colorToHexValues,
} from "@clovis/server/src/app/config/colors/colors";
import { CheckIcon } from "@heroicons/react/outline";
import React from "react";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { Box } from "~/design-system/Box";
import { Icon } from "~/design-system/Icon";
import { Validation } from "~/screens/App/screens/Project/Tasks/types";

const StyledTaskNumber = styled("span", {
  alignItems: "center",
  borderRadius: "50%",
  color: "$white",
  defaultVariants: {
    size: "medium",
  },
  display: "flex",
  fontWeight: "$bold",
  justifyContent: "center",
  variants: {
    closed: {
      true: {
        backgroundColor: "$gray400 !important",
        color: "$gray50 !important",
      },
    },
    isValidatedBy: {
      [Validation.Org]: {
        backgroundColor: "$gray300 !important",
        color: "$sky500 !important",
      },
      [Validation.User]: {
        backgroundColor: "$gray300 !important",
        color: "$black !important",
      },
      [Validation.External]: {},
      [Validation.None]: {},
    },
    reportMode: {
      true: {
        borderRadius: "1rem",
        fontSize: "1rem",
        fontWeight: "bold",
        height: "auto",
        textAlign: "center",
        width: "100%",
      },
    },
    size: {
      medium: {
        borderRadius: "1.25rem",
        height: "2.5rem",
        minWidth: "2.5rem",
        padding: "0.2rem",
      },
      small: {
        borderRadius: "1rem",
        height: "2rem",
        minWidth: "2rem",
        padding: "0.15rem",
      },
      xsmall: {
        borderRadius: "0.5rem",
        height: "1rem",
        minWidth: "1rem",
        padding: "0.1rem",
      },
    },
  },
});

const BadgeValidationExternal = styled("div", {
  "&": {
    alignItems: "center",
    backgroundColor: "$gray300",
    borderRadius: "5rem",
    color: "$gray400",
    display: "flex",
    fontSize: "0.75rem",
    fontWeight: "bold",
    height: "1.15rem",
    justifyContent: "center",
    minWidth: "1.15rem",
    padding: "0 0.3rem",
    width: "initial",
    ...tw`ring-1.5 ring-white`,
  },
});

type TaskNumberProps = React.ComponentProps<typeof StyledTaskNumber> & {
  children: React.ReactNode;
  color?: Color;
  closed?: boolean;
  /* We put custom css here because the style of reports with Carbone cannot be fully matching
  css, so we constraint css with what he docx / pdf renders */
  reportMode?: boolean;
};

/* Note: this component doesn't provide the notification badge, use ProjectTasksListItemBadge for that */
const TaskNumber = (props: TaskNumberProps) => {
  const color = colorToHexValues.get(props.color ?? Color.Sky)!;

  return (
    <StyledTaskNumber
      css={{ backgroundColor: color.plain.background, color: color.plain.text }}
      {...props}
    >
      {props.isValidatedBy === "external" && props.reportMode && (
        <Box position="absolute" css={{ right: "$-1", top: "$-1" }}>
          <BadgeValidationExternal>
            <Icon label="task-external-validation" size="xxsmall">
              <CheckIcon />
            </Icon>
          </BadgeValidationExternal>
        </Box>
      )}
      {props.children}
    </StyledTaskNumber>
  );
};

export { TaskNumber };
