import { BellIcon, SupportIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import tw from "twin.macro";
import { styled } from "~/config/stitches";
import { useUser } from "~/config/user/UserProvider";
import { useWorkerStatusContext } from "~/config/user/workerstatus/WorkerStatus";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { DrawerTrigger } from "~/design-system/Drawer";
import { Icon } from "~/design-system/Icon";
import { SettingsDrawer } from "~/screens/App/components/NavigationDrawer/components/SettingsDrawer/SettingsDrawer";
import { AllNotificationsBadge } from "~/screens/App/components/NotificationsBadge/AllNotificationsBadge";
import { IntercomNotificationsBadge } from "~/screens/App/components/NotificationsBadge/IntercomNotificationsBadge";
import { OrgManageMembersBadge } from "~/screens/App/components/NotificationsBadge/OrgManageMembersBadge";
import { createComponentHook } from "~/types";
import { DesktopNavigationButton } from "./components/DesktopNavigationButton";

const StyledRoot = styled("ul", {
  ...tw`w-full flex flex-col shadow-md rounded-lg`,
  backgroundColor: "#FFFFFFB2",
  gap: "$xxsmall",
  padding: "$xxsmall",
});

const useDesktopNavigationButtonGroup = createComponentHook(() => {
  const { t } = useTranslation();
  const { user } = useUser();
  const IntercomContext = useIntercom();
  const { hasPending } = useWorkerStatusContext();

  const buttons = [
    {
      as: "button",
      "data-intercom-target": "desktop-intercom-chat-button",
      display: true,
      icon: (
        <Box display="flex" position="relative" css={{ height: "auto" }}>
          <Icon size="medium" label="notif icon" color="neutral">
            <SupportIcon />
          </Icon>
          <Box position="absolute" css={{ right: "$-3", top: "$-2" }}>
            <IntercomNotificationsBadge />
          </Box>
        </Box>
      ),
      name: t("Layout.Desktop.DesktopNavigationButtonGroup.help", "Help"),
      onClick: () => {
        IntercomContext.show();
      },
    },
    {
      as: Link,
      "data-intercom-target": "desktop-notifications-center-button",
      display: true,
      icon: (
        <Box position="relative">
          <Icon size="small" label="notif icon" color="neutral">
            <BellIcon />
          </Icon>
          <Box position="absolute" css={{ right: "$-3", top: "$-2" }}>
            <AllNotificationsBadge />
          </Box>
        </Box>
      ),
      name: t("Layout.Desktop.DesktopNavigationButtonGroup.notif", "Notif"),
      to: "/user/notifications",
    },
    {
      "data-intercom-target": "desktop-profile-button",
      display: true,
      icon: (
        <Box display="flex" position="relative" css={{ height: "auto" }}>
          <Avatar
            src={user.user_avatar?.public_url ?? undefined}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            alt={user.full_name!}
            size="small"
            isLoading={hasPending}
          />
          {user.active_org?.org.id && (
            <Box position="absolute" css={{ right: "$-2", top: "$-1" }}>
              <OrgManageMembersBadge orgId={user.active_org?.org.id} />
            </Box>
          )}
        </Box>
      ),
      name: t("Layout.Desktop.DesktopNavigationButtonGroup.me", "Me"),
      wrapper: (children: React.ReactElement) => (
        <SettingsDrawer
          orgs={user.active_org?.org ? [user.active_org?.org] : []}
          activeOrgId={user.active_org?.org.id}
        >
          <DrawerTrigger asChild>{children}</DrawerTrigger>
        </SettingsDrawer>
      ),
    },
  ];

  return {
    state: {
      buttons,
    },
  };
});

function DesktopNavigationButtonGroup() {
  const { state } = useDesktopNavigationButtonGroup();
  return (
    <StyledRoot>
      {state.buttons.map((button, index) => {
        const { display, ...buttonProps } = button;
        if (display) {
          return <DesktopNavigationButton key={index} {...buttonProps} />;
        }
      })}
    </StyledRoot>
  );
}

export { DesktopNavigationButtonGroup };
