import { LogoutIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Undraw from "react-undraw";
import { useIntercom } from "react-use-intercom";
import { env } from "~/config/env";
import { captureException } from "~/config/sentry";
import { colors } from "~/config/stitches/colors";
import { useUser } from "~/config/user/UserProvider";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { useLogout } from "~/screens/App/utils/useLogout/useLogout";
import { createComponentHook } from "~/types";

type ErrorPageProps = {
  errorObject?: Error;
  componentStack?: string;
  eventId?: string;
  customErrorMessage?: string;
  customErrorSubtitle?: string;
};

const useErrorPage = createComponentHook((props: ErrorPageProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { logout } = useLogout();
  const IntercomContext = useIntercom();

  const openIntercom = () => {
    if (IntercomContext) {
      IntercomContext.show();
    }
  };

  const handleLogoutClick = async () => {
    await logout();
  };

  const sendLogs = () => {
    const sentryContext = {
      react: { componentStack: props.componentStack },
      tags: {
        // Inspect inside sentry this log to detect a specific debug from user
        ActionOrigin: "DirectClickLog",
      },
      user: user,
    };

    captureException(props.errorObject, sentryContext);

    /* Checking if sentry is well initialized, cf: packages/client/src/config/sentry/index.ts:72 */
    alert(
      `VITE_SENTRY_DSN: ${env.VITE_SENTRY_DSN} – VITE_SENTRY_ORG_SLUG: ${env.VITE_SENTRY_ORG_SLUG} – VITE_RELEASE: ${env.VITE_RELEASE}`
    );
    alert(`sentryContext: ${JSON.stringify(sentryContext)}`);
    alert(
      `eventId: ${JSON.stringify(
        props.eventId
      )} — componentStack: ${JSON.stringify(props.componentStack)}`
    );
    alert(`errorObject: ${JSON.stringify(props.errorObject)}`);
  };

  return {
    actions: {
      handleLogoutClick,
      openIntercom,
      sendLogs,
    },
    t,
  };
});

const ErrorPage = (props: ErrorPageProps) => {
  const { actions, t } = useErrorPage(props);

  return (
    <div>
      <Stack align="center" space="small">
        <Box style={{ maxHeight: "67vh" }} width="full" height="full">
          <Undraw
            name="warning"
            primaryColor={colors.emerald500}
            height="100%"
          />
        </Box>
        {/* Use this button with user to debug an opaque error */}
        <Button
          onClick={actions.sendLogs}
          dataIntercomTarget="error-technical-log"
          color="neutral"
          link={true}
          css={{
            cursor: "default !important",
            textDecoration: "none !important",
          }}
        >
          {props.customErrorMessage ??
            t(
              "components.Error",
              "An error happened. Please contact our team."
            )}
        </Button>
        {props.customErrorSubtitle && (
          <Text size="xsmall" tone="critical">
            {props.customErrorSubtitle}
          </Text>
        )}
        <Button
          tone="brandAccent"
          onClick={actions.openIntercom}
          dataIntercomTarget="error-tell-us-more"
        >
          {t("component.Error.ReportButtonChat", "Chat with our team")}
        </Button>
        <Button
          variant="transparent"
          tone="brandAccent"
          as="a"
          href="/"
          dataIntercomTarget="error-back-home-button"
        >
          {t("component.Error.GoBackHome", "Or go back to the Home page")}
        </Button>
        <Button
          dataIntercomTarget="profile-menu-logout-button"
          leadingIcon={<LogoutIcon />}
          variant="transparent"
          onClick={actions.handleLogoutClick}
        >
          {t(
            "screens.NavigationDrawer.SettingsDrawerContent.logout",
            "Log out"
          )}
        </Button>
      </Stack>
    </div>
  );
};

export { ErrorPage };
