import create from "zustand";

type NavigationDrawerStoreProps = {
  groupID: string | null;
  setGroupID: (groupID: string | null) => void;
};

const useNavigationDrawerStore = create<NavigationDrawerStoreProps>((set) => ({
  groupID: null,
  setGroupID: (groupID: string | null) => set(() => ({ groupID })),
}));

export { useNavigationDrawerStore };
