import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import type {
  ActionPerformed,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { PushNotifications as PushNotificationsPlugin } from "@capacitor/push-notifications";
import * as React from "react";
import { useNavigate } from "react-router";
import create from "zustand";
import { env } from "~/config/env";
import {
  getFirebaseToken,
  hasNotificationPermission,
  initServiceWorker,
  onPushMessageListener,
} from "~/config/firebase";
import { captureException } from "~/config/sentry";
import { useUser } from "~/config/user/UserProvider";
import { useSaveFirebaseTokenMutation } from "./PushNotifications.graphql";

type FirebaseTokenStore = {
  token: string | null;
  setToken: (token: string) => void;
};

const useFirebaseToken = create<FirebaseTokenStore>((set) => ({
  setToken: (token: string) => set({ token }),
  token: null,
}));

type PushNotificationsProps = {
  children: React.ReactNode;
};

function cleanPushNotificationsListeners() {
  void PushNotificationsPlugin.removeAllListeners()
    // On web capacitor will throw an error "PushNotificationPlugin not implemented"
    .catch((error) => {
      // Skip the "UNIMPLEMENTED" error since it's expected on web platforms
      if (
        error &&
        typeof error === "object" &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        error.code === "UNIMPLEMENTED"
      ) {
        return;
      } else {
        captureException(new Error(JSON.stringify(error)));
      }
    });
}

const usePushNotifications = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [, saveFirebaseToken] = useSaveFirebaseTokenMutation();

  const { setToken, token } = useFirebaseToken((state) => state);

  const loadedUserId = user.id;

  // When the user change, and his new "firebase token" is set
  // we want to store this token into our backend
  // The following useEffect is in charge of that:
  React.useEffect(() => {
    console.info("Clovis firebaseToken is: ", token);
    async function saveUserDeviceToken() {
      const infoDevice = await Device.getInfo();
      if (token) {
        const input = {
          appVersion: env.VITE_APP_VERSION,
          manufacturer: infoDevice.manufacturer
            ? infoDevice.manufacturer
            : "unknown",
          model: infoDevice.model ? infoDevice.model : "unknown",
          operatingSystem: infoDevice.operatingSystem
            ? infoDevice.operatingSystem
            : "unknown",
          osVersion: infoDevice.osVersion ? infoDevice.osVersion : "unknown",
          platform: infoDevice.platform ? infoDevice.platform : "unknown",
          token: token,
          webViewVersion: infoDevice.webViewVersion
            ? infoDevice.webViewVersion
            : "unknown",
        };
        await saveFirebaseToken({ input })
          .then((result) => {
            if (
              result?.error ||
              result?.data?.saveFirebaseToken?.__typename ===
                "SaveFirebaseTokenErrors"
            ) {
              captureException(new Error(JSON.stringify(result)));
            }
          })
          .catch((error) => {
            captureException(new Error(JSON.stringify(error)));
          });
      }
    }
    if (loadedUserId && token) {
      void saveUserDeviceToken();
    }
  }, [token, loadedUserId]);

  // When the user change or connect, we need to initialize the firebase
  // messaging depending on the platform (web / mobile)
  // This useEffect is in charge of that, it'll watch the current loaded user
  // and will retrieve (if already exist) or create a valid firebase_token for this user
  // for this device.
  // On web it'll: retrieve a firebase token, init a service worker
  // On Mobile it'll: setup and configure PushNotificationsPlugin from capacitor to handle native notifications
  React.useEffect(() => {
    async function init() {
      // Desktop management
      if (Capacitor.isNativePlatform() === false) {
        await hasNotificationPermission().then(async (hasPermission) => {
          if (!hasPermission) {
            return;
          }

          // Navigator management
          try {
            await initServiceWorker();
            const token = await getFirebaseToken();
            await onPushMessageListener();
            if (token) {
              setToken(token);
            }
          } catch (err) {
            captureException(new Error(JSON.stringify(err)));
          }
        });
      } else {
        const result = await PushNotificationsPlugin.requestPermissions();

        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          void PushNotificationsPlugin.register();
        } else {
          // Show some error
          console.warn("Push notification permission isn't granted");
        }

        // On success, we should be able to receive notifications
        void PushNotificationsPlugin.addListener(
          "registration",
          (token: Token) => {
            setToken(token.value);
          }
        );

        // Some issue with our setup and push will not work
        void PushNotificationsPlugin.addListener(
          "registrationError",
          (error: any) => {
            captureException(new Error(JSON.stringify(error)));
          }
        );

        // Show us the notification payload if the app is open on our device
        void PushNotificationsPlugin.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            console.info("Clovis push notification received :", notification);
            // This won't work because of the implementation of the push notification plugin by capacitor
            // which will never call this code when the app is not in foreground
            // see: https://github.com/ionic-team/capacitor/issues/4145
            // void badgeHook.increaseCount();
          }
        );

        // Method called when tapping on a notification
        void PushNotificationsPlugin.addListener(
          "pushNotificationActionPerformed",
          (actionNotification: ActionPerformed) => {
            console.info(
              "Clovis pushNotificationActionPerformed: ",
              actionNotification
            );
            if (actionNotification.actionId === "tap") {
              const notification = actionNotification.notification;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (notification.data?.redirect_path) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                navigate(notification.data.redirect_path as string);
              }
            }
          }
        );
      }
    }
    if (loadedUserId) {
      void init().catch((err) => {
        captureException(new Error(JSON.stringify(err)), {
          extra: {
            scope: "usePushNotifications error",
          },
        });
      });
    }
    return cleanPushNotificationsListeners;
  }, [loadedUserId]);
};

function PushNotifications(props: PushNotificationsProps) {
  usePushNotifications();

  return <>{props.children}</>;
}

export { PushNotifications, useFirebaseToken };
