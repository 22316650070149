import type * as Types from '../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserActiveOrgRole_DataFragment = { role: { name: string } };

export type UserActiveOrg_DataFragment = { org: { id: string, name: string, background?: string | null | undefined, address?: { lat?: number | null | undefined, lng?: number | null | undefined } | null | undefined, org_avatar?: { public_url: string } | null | undefined, project?: { id: string } | null | undefined } };

export type UserProvider_UserQueryVariables = Types.Exact<{
  auth0_id: Types.Scalars['String'];
}>;


export type UserProvider_UserQuery = { users: Array<{ id: string, full_name?: string | null | undefined, language: string, stream_user_id: string, is_locked: boolean, country_code?: string | null | undefined, user_avatar?: { public_url: string } | null | undefined, contact?: { email?: string | null | undefined, phone?: string | null | undefined } | null | undefined, teams: Array<{ team_id: string, team: { project_id: string } }>, algolia_key?: { key: string } | null | undefined, active_org?: { role: { name: string }, org: { id: string, name: string, background?: string | null | undefined, address?: { lat?: number | null | undefined, lng?: number | null | undefined } | null | undefined, org_avatar?: { public_url: string } | null | undefined, project?: { id: string } | null | undefined } } | null | undefined, stream_chat_token?: { token: string } | null | undefined }> };

export type UserTeams_DataFragment = { team_id: string, team: { project_id: string } };

export const UserActiveOrgRole_DataFragmentDoc = gql`
    fragment UserActiveOrgRole_data on orgs_to_users {
  role {
    name
  }
}
    `;
export const UserActiveOrg_DataFragmentDoc = gql`
    fragment UserActiveOrg_data on orgs_to_users {
  org {
    address {
      lat
      lng
    }
    id
    name
    org_avatar {
      public_url
    }
    background
    project {
      id
    }
  }
}
    `;
export const UserTeams_DataFragmentDoc = gql`
    fragment UserTeams_data on teams_to_users {
  team_id
  team {
    project_id
  }
}
    `;
export const UserProvider_UserDocument = gql`
    query UserProvider_user($auth0_id: String!) {
  users(where: {auth0_id: {_eq: $auth0_id}}, limit: 1) {
    id
    user_avatar {
      public_url
    }
    full_name
    language
    stream_user_id
    contact {
      email
      phone
    }
    teams {
      ...UserTeams_data
    }
    algolia_key {
      key
    }
    active_org {
      ...UserActiveOrgRole_data
      ...UserActiveOrg_data
    }
    stream_chat_token {
      token
    }
    is_locked
    country_code
  }
}
    ${UserTeams_DataFragmentDoc}
${UserActiveOrgRole_DataFragmentDoc}
${UserActiveOrg_DataFragmentDoc}`;

export function useUserProvider_UserQuery(options: Omit<Urql.UseQueryArgs<UserProvider_UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserProvider_UserQuery>({ query: UserProvider_UserDocument, ...options });
};