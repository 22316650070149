import type * as Types from '../../../../../../../../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProjectRoleMutationVariables = Types.Exact<{
  input: Types.UpdateProjectRoleInput;
}>;


export type UpdateProjectRoleMutation = { updateProjectRole?: { __typename: 'UpdateProjectRoleErrors' } | { __typename: 'UpdateProjectRoleSuccess', project_id: string } | null | undefined };

export type UpdateUserTeamsMutationVariables = Types.Exact<{
  input: Types.UpdateUserTeamsInput;
}>;


export type UpdateUserTeamsMutation = { updateUserTeams?: { __typename: 'UpdateUserTeamsErrors' } | { __typename: 'UpdateUserTeamsSuccess', team_ids: Array<string | null | undefined> } | null | undefined };

export type UpdateProjectUserInfosMutationVariables = Types.Exact<{
  input: Types.UpdateProjectUserInfosInput;
}>;


export type UpdateProjectUserInfosMutation = { updateProjectUserInfos?: { __typename: 'UpdateProjectUserInfosErrors', errors: Array<{ __typename: 'CannotModifyEmailOfActiveUser' } | { __typename: 'CannotSetGreaterProjectRoleThanYours' } | { __typename: 'EmailAlreadyUsedByAnotherUser' } | { __typename: 'InputValidationError' } | { __typename: 'ProjectAccessDenied' } | { __typename: 'ProjectDoesNotExist' } | { __typename: 'ProjectNeedsAtLeastOneOwner' } | { __typename: 'TeamAccessDenied' } | { __typename: 'TeamDoesNotExist' } | { __typename: 'UpdateProjectUserInfosPermissionDenied' } | { __typename: 'UserDoesNotExist' }> } | { __typename: 'UpdateProjectUserInfosSuccess', project_id: string } | null | undefined };

export type PrepareProjectUserAvatarForUploadMutationVariables = Types.Exact<{
  input: Types.PrepareProjectUserAvatarForUploadInput;
}>;


export type PrepareProjectUserAvatarForUploadMutation = { prepareProjectUserAvatarForUpload?: { __typename: 'PrepareProjectUserAvatarForUploadErrors', errors: Array<{ __typename: 'InputValidationError' } | { __typename: 'ProjectAccessDenied' } | { __typename: 'ProjectDoesNotExist' } | { __typename: 'UpdateProjectUserInfosPermissionDenied' } | { __typename: 'UserDoesNotExist' }> } | { __typename: 'PrepareProjectUserAvatarForUploadSuccess', key: string, url: string } | null | undefined };

export type EditUserForm_DataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditUserForm_DataQuery = { projects: Array<{ members: Array<{ user: { first_name: string, last_name: string, company_name?: string | null | undefined, active_org?: { org: { name: string, id: string, org_avatar?: { public_url: string } | null | undefined } } | null | undefined } }> }> };


export const UpdateProjectRoleDocument = gql`
    mutation updateProjectRole($input: UpdateProjectRoleInput!) {
  updateProjectRole(input: $input) {
    __typename
    ... on UpdateProjectRoleSuccess {
      project_id
    }
  }
}
    `;

export function useUpdateProjectRoleMutation() {
  return Urql.useMutation<UpdateProjectRoleMutation, UpdateProjectRoleMutationVariables>(UpdateProjectRoleDocument);
};
export const UpdateUserTeamsDocument = gql`
    mutation updateUserTeams($input: UpdateUserTeamsInput!) {
  updateUserTeams(input: $input) {
    __typename
    ... on UpdateUserTeamsSuccess {
      team_ids
    }
  }
}
    `;

export function useUpdateUserTeamsMutation() {
  return Urql.useMutation<UpdateUserTeamsMutation, UpdateUserTeamsMutationVariables>(UpdateUserTeamsDocument);
};
export const UpdateProjectUserInfosDocument = gql`
    mutation updateProjectUserInfos($input: UpdateProjectUserInfosInput!) {
  updateProjectUserInfos(input: $input) {
    __typename
    ... on UpdateProjectUserInfosSuccess {
      project_id
    }
    ... on UpdateProjectUserInfosErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function useUpdateProjectUserInfosMutation() {
  return Urql.useMutation<UpdateProjectUserInfosMutation, UpdateProjectUserInfosMutationVariables>(UpdateProjectUserInfosDocument);
};
export const PrepareProjectUserAvatarForUploadDocument = gql`
    mutation prepareProjectUserAvatarForUpload($input: PrepareProjectUserAvatarForUploadInput!) {
  prepareProjectUserAvatarForUpload(input: $input) {
    __typename
    ... on PrepareProjectUserAvatarForUploadSuccess {
      key
      url
    }
    ... on PrepareProjectUserAvatarForUploadErrors {
      errors {
        __typename
      }
    }
  }
}
    `;

export function usePrepareProjectUserAvatarForUploadMutation() {
  return Urql.useMutation<PrepareProjectUserAvatarForUploadMutation, PrepareProjectUserAvatarForUploadMutationVariables>(PrepareProjectUserAvatarForUploadDocument);
};
export const EditUserForm_DataDocument = gql`
    query EditUserForm_data {
  projects {
    members(
      where: {_or: [{user: {active_org: {_not: {org: {id: {_is_null: true}}}}}}, {user: {company_name: {_is_null: false}}}]}
    ) {
      user {
        first_name
        last_name
        company_name
        active_org {
          org {
            name
            org_avatar {
              public_url
            }
            id
          }
        }
      }
    }
  }
}
    `;

export function useEditUserForm_DataQuery(options: Omit<Urql.UseQueryArgs<EditUserForm_DataQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<EditUserForm_DataQuery>({ query: EditUserForm_DataDocument, ...options });
};