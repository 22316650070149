import { FileApprovalStatusId } from "@clovis/server/src/app/domain/file/approvals/status";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import * as React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { nullable, useForm } from "~/config/react-hook-form";
import { captureException } from "~/config/sentry";
import { uppy } from "~/config/uppy";
import type { FileMeta } from "~/config/uppy/types";
import { Badge } from "~/design-system/Badge";
import type { ButtonProps } from "~/design-system/Button";
import { Button } from "~/design-system/Button";
import { Form } from "~/design-system/Form";
import { Stack } from "~/design-system/Stack";
import { TextField } from "~/design-system/TextField";
import type { ApprovalAttachments } from "~/screens/App/screens/Project/Docs/screens/ProjectDocumentsScreen/components/FilesFolders/components/AssigneesApprovalsAndViewsDrawer/components/AssigneesApprovalAndViewsDrawerContent/components/AssigneeApprovalAndViewBadge/AssigneeApprovalBadge";
import { useFilesFoldersAttachmentStore } from "~/screens/App/screens/Project/Docs/screens/ProjectDocumentsScreen/components/FilesFolders/FilesFolders.store";
import { TaskFilesBadges } from "~/screens/App/screens/Project/Tasks/components/CreateTaskForm/components/TaskFilesBadges/TaskFilesBadges";
import type { TaskLocationOnFileProps } from "~/screens/App/screens/Project/Tasks/components/CreateTaskForm/components/TaskLocationOnFile/TaskLocationOnFile";
import { createComponentHook } from "~/types";
import { AddAttachmentApprovalButton } from "../AddAttachmentApprovalButton/AddAttachmentApprovalButton";
import {
  useAddFileApprovalsFileVersionsMutation,
  useAddUserApprovalToFileVersionMutation,
  useAddUserMultiApprovalToFilesVersionMutation,
  useFinalizeUploadedFileApprovalsAttachmentMutation,
  usePrepareFileApprovalsAttachmentForUploadMutation,
} from "./AddApprovalForm.graphql";

const MODE_DEFAULT_STATUS_MAP = {
  deny: FileApprovalStatusId.DENIED,
  nc: FileApprovalStatusId.NC,
  validate: FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS,
  validateWithComments: FileApprovalStatusId.APPROVED_WITH_COMMENTS,
};

const BUTTON_COLOR_STATUS_MAP: Record<
  FileApprovalStatusId,
  ButtonProps["tone"]
> = {
  [FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS]: "brandAccent",
  [FileApprovalStatusId.APPROVED_WITH_COMMENTS]: "caution",
  [FileApprovalStatusId.DENIED]: "critical",
  [FileApprovalStatusId.NC]: "brandAccent",
};

const useAddApprovalForm = createComponentHook(
  (props: AddApprovalFormProps) => {
    const { t } = useTranslation();

    const { setNumberOfFiles } = useFilesFoldersAttachmentStore((state) => ({
      setNumberOfFiles: state.setNumberOfFiles,
    }));

    let leadingIcon;
    if (props.mode === "deny") {
      leadingIcon = <XIcon />;
    }
    if (props.mode === "validateWithComments") {
      leadingIcon = <CheckIcon />;
    }

    const STATUS_TO_BUTTON_MESSAGE_MAP = {
      [FileApprovalStatusId.NC]: t(
        "screens.AddApprovalForm.submitButtonText.NC",
        "I'm not concerned"
      ),
      [FileApprovalStatusId.DENIED]: t(
        "screens.AddApprovalForm.submitButtonText.DENIED",
        "Deny"
      ),
      [FileApprovalStatusId.APPROVED_WITH_COMMENTS]: t(
        "screens.AddApprovalForm.submitButtonText.APPROVED_WITH_COMMENTS",
        "Approve with Comments"
      ),
      [FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS]: t(
        "screens.AddApprovalForm.submitButtonText.APPROVED_WITHOUT_COMMENTS",
        "Approve"
      ),
    };

    const displayComment = props.mode !== "validate";

    const [readyToSubmit, setReadyToSubmit] = React.useState(
      props.mode === "nc" || props.mode === "validate"
    );

    const form = useForm(
      z.object({
        comment: z.string().min(0).nullable().optional(),
        file_version_id: z.string().uuid().optional(),
        file_version_ids: z
          .array(z.string().uuid())
          .min(1)
          .nonempty()
          .optional(),
        status: z.nativeEnum(FileApprovalStatusId),
      }),
      {
        defaultValues: {
          comment: props.commentDefaultValue ?? "",
          file_version_id: props.fileVersionId,
          file_version_ids: props.fileVersionIds,
          status: MODE_DEFAULT_STATUS_MAP[props.mode],
        },
      }
    );

    React.useEffect(() => {
      form.setValue("status", MODE_DEFAULT_STATUS_MAP[props.mode]);
    }, [props.mode, form.setValue]);

    const watchStatus = form.watch("status");
    const watchComments = form.watch("comment");

    const hasComments = Boolean(watchComments);

    React.useEffect(() => {
      setReadyToSubmit(true);
      form.clearErrors();
      if (
        (props.mode === "deny" && hasComments === false) ||
        (props.mode === "validateWithComments" && hasComments === false)
      ) {
        setReadyToSubmit(false);
        form.setError("comment", {
          message: t(
            "screens.AddApprovalForm.nonEmptyCommentError",
            "You must add a comment"
          ),
        });
      }
    }, [hasComments, props.mode]);

    const [, addUserApprovalToFileVersion] =
      useAddUserApprovalToFileVersionMutation();
    const [, addApproval] = useAddUserMultiApprovalToFilesVersionMutation();

    const getCommentValue = (
      status: FileApprovalStatusId,
      comment?: string | null
    ) => {
      if (status === FileApprovalStatusId.APPROVED_WITHOUT_COMMENTS) {
        return null;
      }
      return comment ?? undefined;
    };

    const handleSubmit = form.handleSubmit(async (input) => {
      setReadyToSubmit(false);
      if (input.file_version_id) {
        const inputData = {
          comment: getCommentValue(input.status, input.comment),
          file_version_id: input.file_version_id,
          status: input.status,
        };

        const { data, error } = await addUserApprovalToFileVersion(
          {
            input: inputData,
          },
          {
            additionalTypenames: ["file_approvals", "files"],
          }
        );

        setNumberOfFiles({
          fileID: input.file_version_id,
          numberOfAttachments: attachments.length,
          numberOfLinkedFiles: linkedFile.length,
        });
        if (
          data?.addUserApprovalToFileVersion?.__typename ===
          "AddUserApprovalToFileVersionSuccess"
        ) {
          await handlePrepareFileApprovalsAttachmentForUpload({
            file_approval_id:
              data?.addUserApprovalToFileVersion?.file_approval_id,
          });
          await handleUploadLinkendFile({
            file_approval_id:
              data?.addUserApprovalToFileVersion?.file_approval_id,
          });
          toast.success(
            t(
              "screens.AddApprovalForm.addUserApprovalToFileVersionSuccessToast",
              "Your approval has been added to the file version"
            )
          );
          props.onSuccess?.(props.fileVersionId);
        } else {
          captureException(error, {
            extra: {
              file_version_id: input.file_version_id,
            },
          });
          toast.error(
            t(
              "screens.AddApprovalForm.addUserApprovalToFileVersionErrorToast",
              "An error occurred while trying to add your approval"
            )
          );
        }
      } else {
        const inputData = {
          comment: getCommentValue(input.status, input.comment),
          files: input.file_version_ids!,
          status: input.status,
        };
        const { data, error } = await addApproval(
          {
            input: inputData,
          },
          {
            additionalTypenames: ["files"],
          }
        );
        if (
          data?.addUserMultiApprovalToFilesVersion?.__typename ===
          "AddMultiUserApprovalToFileVersionSuccess"
        ) {
          toast.success(
            t(
              "screens.Projects.Docs.MultiApprovalValidationFileFolderDialogContent.approvalSuccess",
              "Your files have been approved"
            )
          );

          props.onSuccess?.(
            data.addUserMultiApprovalToFilesVersion?.file_ids?.[0]
              ?.file_version_id
          );
        } else {
          captureException(error, {
            extra: {
              file_version_id: input.file_version_id,
            },
          });
          toast.error(
            t(
              "screens.Projects.Docs.MultiApprovalValidationFileFolderDialogContent.approvalError",
              "An error occurred while trying to approve those files"
            )
          );
        }
      }
      setReadyToSubmit(props.mode === "nc" || props.mode === "validate");
    });

    // Create List Of attachment Files
    const initialAttachments = props.editForm
      ? props.attachmentsDefaultValue ?? []
      : [];
    const [attachments, setAttachments] =
      React.useState<(ApprovalAttachments | File)[]>(initialAttachments);

    const handleAttachmentChange: React.ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      const newFiles = Array.from(e.target.files ?? []);

      // look any repeated file
      const uniqueNewFiles = newFiles.filter(
        (newFile) =>
          !attachments.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      // update files
      setAttachments((prevAttachments) =>
        prevAttachments.concat(uniqueNewFiles)
      );
    };

    // Delete Attachment File
    const handleDeleteAttachmentChange = (file: ApprovalAttachments | File) => {
      setAttachments(attachments.filter((at) => at.name !== file.name));
    };

    // Prepare File To uploads
    const [, prepareFileApprovalsAttachmentForUpload] =
      usePrepareFileApprovalsAttachmentForUploadMutation();

    const [, finalizeUploadedFileApprovalsAttachment] =
      useFinalizeUploadedFileApprovalsAttachmentMutation();
    const handlePrepareFileApprovalsAttachmentForUpload = async ({
      file_approval_id,
    }: {
      file_approval_id: string;
    }) => {
      // because it is posible to come form edit form, this information is the normal file
      const attachmentFiles: File[] = attachments.filter(
        (attachment) =>
          attachment instanceof File &&
          attachment.webkitRelativePath !== undefined
      ) as File[];

      const filesMeta = await Promise.all(
        attachmentFiles.map((file) =>
          prepareFileApprovalsAttachmentForUpload(
            {
              input: {
                fileApprovalId: file_approval_id,
                name: file.name,
              },
            },
            {
              additionalTypenames: [
                "file_approvals",
                "files",
                "file_approval_attachments",
              ],
            }
          )
        )
      );
      // error file
      //
      attachmentFiles.forEach((file, index) => {
        const fileMeta = filesMeta[index];
        if (
          fileMeta.data?.prepareFileApprovalsAttachmentForUpload?.__typename !==
          "PrepareFileApprovalsAttachmentForUploadSuccess"
        ) {
          toast.success(
            t(
              "screens.AddApprovalForm.AddAttachmentsFiles.error",
              "Your approval file has not been attached to the file version."
            )
          );
          return;
        }
        const { key, url } =
          fileMeta.data.prepareFileApprovalsAttachmentForUpload;
        uppy.addFile<FileMeta>({
          data: file,
          meta: {
            internalFileType: "fileApprovalAttachment",
            key,
            onFinalizeEnd: (_meta, _data, error) => {
              // Reset the fileInput value to "empty" once the upload is done
              if (error) {
                toast.error(
                  t(
                    "screens.fileApprovalAttachment.finalizeFilesUploadError",
                    "An error occured while uploading approvals attachment"
                  )
                );
              } else {
                toast.success(
                  <span data-intercom-target="task-attachment-uploaded">
                    {t(
                      "screens.fileApprovalAttachment.finalizeFilesUploadSuccess",
                      "Approvals attachment successfully uploaded"
                    )}
                  </span>
                );
              }
            },

            url,
          },
          name: file.name,
          size: file.size,
          type: file.type,
        });
        toast.success(
          t(
            "screens.AddApprovalForm.AddAttachmentsFiles.success",
            "Your approval file has been attached to the file version."
          )
        );
      });
      // here we are going to work with the prevoius attachment file just in edit form
      const previousAttachmentFiles: ApprovalAttachments[] = attachments.filter(
        (attachment): attachment is ApprovalAttachments =>
          "creator_id" in attachment && attachment.creator_id !== undefined
      );

      const diffAttacmentsFiles = props.attachmentsDefaultValue?.filter(
        (attachmentDefault) =>
          !previousAttachmentFiles.some(
            (previousAttachment) =>
              previousAttachment.id === attachmentDefault.id
          )
      );
      if (diffAttacmentsFiles && diffAttacmentsFiles?.length > 0) {
        const deleteFiles = await Promise.all(
          diffAttacmentsFiles.map((att) =>
            finalizeUploadedFileApprovalsAttachment(
              {
                input: { id: att.id, key: att.key },
              },
              {
                additionalTypenames: [
                  "file_approvals",
                  "files",
                  "file_approval_attachments",
                ],
              }
            )
          )
        );
        diffAttacmentsFiles.forEach((file, index) => {
          const data = deleteFiles[index].data as {
            __typename?: string;
            file_approval_attachment_id?: string;
          };
          if (
            data?.__typename ===
            "FinalizeUploadedFileApprovalsAttachmentSuccess"
          ) {
            toast.success(
              t(
                "screens.AddApprovalForm.AddAttachmentsFiles.error",
                "Your approval file has not been attached to the file version."
              )
            );
          } else {
            toast.error(
              t(
                "screens.AddApprovalForm.addUserApprovalToFileVersionErrorToast",
                "An error occured while trying to add your approval"
              )
            );
          }
        });
      }
    };

    // this line is from edit form, we need to everything that is in the previous file has the same format
    const defaultLinkedFile = props.editForm
      ? props.linkedFileDefaultValue
          ?.filter((file) => file.file_version_id !== undefined)
          .map((file) => ({
            file_version_id: file.file_version_id!,
          })) ?? []
      : [];
    const [linkedFile, setLinkedFile] = React.useState<
      {
        file_version_id: string;
      }[]
    >(defaultLinkedFile);

    const handleLinkedFile: TaskLocationOnFileProps["onFileSelected"] = (
      fileVersionSelected
    ) => {
      setLinkedFile((currentFiles) => {
        const isFileAlreadyAdded = currentFiles.some(
          (file) => file.file_version_id === fileVersionSelected.file_version_id
        );

        // Si no está en la lista, agregarlo
        if (!isFileAlreadyAdded) {
          return [
            ...currentFiles,
            { file_version_id: fileVersionSelected.file_version_id },
          ];
        }
        return currentFiles;
      });
    };

    const removeLinkedFile = (file_version_id: string) => {
      setLinkedFile(
        linkedFile.filter((at) => at.file_version_id !== file_version_id)
      );
    };

    const [, addFileApprovalsFileVersions] =
      useAddFileApprovalsFileVersionsMutation();
    const handleUploadLinkendFile = async ({
      file_approval_id,
    }: {
      file_approval_id: string;
    }) => {
      const deletedFileUpload =
        props.linkedFileDefaultValue?.filter(
          (defaultFile) =>
            !linkedFile.some(
              (linked) => linked.file_version_id === defaultFile.file_version_id
            )
        ) ?? [];

      const addedFileUpload = linkedFile.filter(
        (linked) =>
          !props.linkedFileDefaultValue?.some(
            (defaultFile) =>
              defaultFile.file_version_id === linked.file_version_id
          )
      );

      const fileToUploaded = [...deletedFileUpload, ...addedFileUpload];

      const fileUpload = await Promise.all(
        fileToUploaded.map((linked) => {
          if (!linked.file_version_id) return {};
          return addFileApprovalsFileVersions({
            input: {
              fileApprovalId: file_approval_id,
              fileVersionId: linked.file_version_id,
            },
          });
        })
      );
      fileUpload.forEach((file) => {
        const data = file as {
          // this line is for type problems
          data: {
            addFileApprovalsFileVersions: {
              saved: boolean;
              __typename: string;
            };
          };
        };
        if (
          data?.data?.addFileApprovalsFileVersions?.__typename ===
          "AddFileApprovalsFileVersionsSuccess"
        ) {
          return toast.success(
            t(
              "screens.AddApprovalForm.addUserApprovalToFileVersionSuccessToast",
              "Your file has been added to the approval file version comment"
            )
          );
        }
        toast.success(
          t(
            "screens.AddApprovalForm.addUserApprovalToFileVersionSuccessToast",
            "Your file has been some problmes to the approval file version comment"
          )
        );
      });
    };

    return {
      actions: {
        handleAttachmentChange,
        handleDeleteAttachmentChange,
        handleLinkedFile,
        handleSubmit,
        registerInput: form.register,
        removeLinkedFile,
      },
      state: {
        attachments,
        displayComment,
        errors: form.formState.errors,
        leadingIcon,
        linkedFile,
        readyToSubmit,
        status: watchStatus,
        submitColor: BUTTON_COLOR_STATUS_MAP[watchStatus],
        submitMessage: STATUS_TO_BUTTON_MESSAGE_MAP[watchStatus],
      },
      t,
    };
  }
);

type AddApprovalFormProps = {
  fileVersionId?: string;
  fileVersionIds?: string[];
  mode: "deny" | "nc" | "validate" | "validateWithComments";
  onSuccess?: (fileVersionId?: string, fileVersionIds?: string[]) => unknown;
  projectId: string;
  // this arguments are used to edit the previous approval comment uploaded
  editForm?: boolean;
  commentDefaultValue?: string;
  attachmentsDefaultValue?: ApprovalAttachments[];
  linkedFileDefaultValue?: {
    file_version_id: string | undefined;
  }[];
};

function AddApprovalForm(props: AddApprovalFormProps) {
  const { actions, state, t } = useAddApprovalForm(props);
  return (
    <Form onSubmit={actions.handleSubmit}>
      <Stack space="gutter">
        {state.displayComment && (
          <TextField
            {...actions.registerInput("comment", {
              setValueAs: (v: string) => nullable(v.trim()),
            })}
            label={t("screens.AddApprovalForm.commentsLabel", "Comments")}
            error={state.errors.comment?.message}
            multiline
            rows={4}
            // override the zod schema to remove the "(optional)" text
            required={
              state.status === FileApprovalStatusId.DENIED ||
              state.status === FileApprovalStatusId.APPROVED_WITH_COMMENTS
            }
            dataIntercomTarget="file-approval-mode-comment"
          />
        )}
        {/* Add List of File names */}
        {state.attachments.length &&
          state.attachments?.map((file, key) => (
            <Badge
              key={`attachments-${key}`}
              onRemove={() => actions.handleDeleteAttachmentChange(file)}
            >
              {file.name}
            </Badge>
          ))}
        {state.linkedFile.length && (
          <TaskFilesBadges
            fileVersions={state.linkedFile}
            onRemove={actions.removeLinkedFile}
          />
        )}
        {props.fileVersionId && state.displayComment && (
          <AddAttachmentApprovalButton
            onAttachmentChange={actions.handleAttachmentChange}
            projectId={props.projectId}
            onFileSelected={actions.handleLinkedFile}
          />
        )}
        <Button
          leadingIcon={state.leadingIcon}
          disabled={!state.readyToSubmit}
          size="large"
          type="submit"
          tone={state.submitColor}
          width="full"
          variant="solid"
          dataIntercomTarget="file-viewer-add-approval-submit"
        >
          {state.submitMessage}
        </Button>
      </Stack>
    </Form>
  );
}

export { AddApprovalForm };
export type { AddApprovalFormProps };
