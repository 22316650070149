/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { CSS } from "@stitches/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import type { Options } from "react-select/dist/declarations/src/types";
import type {
  RefCallBack,
  UseFormRegisterReturn,
  UseFormReturn,
} from "~/config/react-hook-form";
import { Controller } from "~/config/react-hook-form";
import type { GroupBase, Props } from "~/config/react-select";
import { CreatableSelect, Select } from "~/config/react-select";
import { Box } from "~/design-system/Box";
import type { FieldProps } from "~/design-system/Field";
import { Field } from "~/design-system/Field";
import { useBreakpoint } from "~/design-system/hooks";
import type { TaskTypeBadge } from "../Badges/TaskBadge";
import { ClearIndicator } from "./components/ClearIndicator";
import { Control } from "./components/Control";
import { CustomValueContainer } from "./components/CustomValueContainer";
import { DropdownIndicator } from "./components/DropdownIndicator";
import { IndicatorsContainer } from "./components/IndicatorsContainer";
import { MobileCustomSelect } from "./components/MobileCustomSelect";
import { MultiValue } from "./components/MultiValue";
// import { Input } from "./components/Input";
import { SelectOption } from "./components/Option";

const enum BasicMultiSelectFieldInputOptionType {
  ORG = "org",
  TEMPORARY_ORG = "temporary_org", // Temporary orgs are filled orgs by users before they officialy create their org inside clovis, that's useful especially when a user needs to make reports by org instantly
  PROJECT = "project",
  PROJECT_CATEGORY = "project_category",
  PROJECT_LABEL = "project_label",
  TEAM = "team",
  USER = "user",
  AUTOCOMPLETE = "autocomplete",
  TASK = "task",
}
type FilterOption<T> = FilterOptionOption<BasicMultiSelectFieldInputOption<T>>;

type BasicMultiSelectFieldInputOption<T = string> = {
  value: T;
  label: string;
  type?: BasicMultiSelectFieldInputOptionType;
  avatar?: string | null;
  color?: string; // color is a hex string
  task?: TaskTypeBadge;
};

type SelectProps<P extends BasicMultiSelectFieldInputOption> = Props<
  BasicMultiSelectFieldInputOption<P>,
  boolean,
  GroupBase<BasicMultiSelectFieldInputOption<P>>
>;

type BasicMultiSelectFieldInputProps<U> = Omit<FieldProps, "children"> &
  UseFormRegisterReturn & {
    // We need any here because react-hook-forms wont play nice with our generics options without that
    control: UseFormReturn<any>["control"];
    options: BasicMultiSelectFieldInputOption<U>[];
    disabledOptions?: BasicMultiSelectFieldInputOption<U>[];
    // You want to use this options when dealing with non-scalar values (ex: objects, ...) when default "===" operator is not enough
    // It will be used to check if the "value" in the options is selected (TODO: give an example)
    compareValue?: (left: any, right: U) => boolean;
    onSelect?: (value: Options<BasicMultiSelectFieldInputOption<U>>) => void;
    defaultValue?: U[];
    defaultInputValue?: string;
    onCreateOption?: (inputValue: string) => Promise<void>;
    /** Function to set the right text in the dropdown while "creating" */
    formatCreateLabel?: (inputValue: string) => string;
    multiple?: boolean;
    autoFocus?: boolean;
    clearButton?: boolean;
    dropdownButton?: boolean;
    placeholder?: string;
    openMenuOnFocus?: boolean;
    isLoading?: boolean;
    isClearable?: boolean;
    onInputChange?: (inputValue: string) => void;
    tabSelectsValue?: boolean;
    onSelectBlur?: React.FocusEventHandler<HTMLInputElement>;
    controlled?: {
      open: boolean;
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    };
    filterOption?: (option: FilterOption<U>, inputValue: string) => boolean;
    cssMobile?: CSS;
  };

function defaultCompareValue<T>(left: T, right: T) {
  return left === right;
}

/** IMPORTANT: don't create exceptions in this component, try to make it as generic as possible, and make higher components adapt their data */
function BasicMultiSelectFieldInputRef<
  P extends BasicMultiSelectFieldInputOption
>(
  props: BasicMultiSelectFieldInputProps<P>,
  _: React.ForwardedRef<RefCallBack>
) {
  const fieldProps: Omit<FieldProps, "children"> = {
    cornerHint: props.cornerHint,
    dataIntercomTarget: props.dataIntercomTarget,
    error: props.error,
    label: props.label,
    message: props.message,
    name: props.name,
    required: props.required,
    rootProps: props.rootProps,
    secondaryLabel: props.secondaryLabel,
    tone: props.tone,
  };
  const { breakpoint } = useBreakpoint();
  const compareValue = props.compareValue ?? defaultCompareValue;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(props.isLoading ?? false);
  const handleCreate = async (inputValue: string) => {
    if (props.onCreateOption) {
      setIsLoading(true);
      await props.onCreateOption(inputValue);
      setIsLoading(false);
    }
  };

  const {
    clearButton = true,
    dropdownButton = true,
    isClearable = false,
    multiple = true,
    openMenuOnFocus = true,
    placeholder,
  } = props;

  // this is a workaround to make sure that the disabled options will not be shown in the dropdown
  // even if they are already selected
  // it is managed by this way to guarantee retrocompatibility
  const toRenderOptions: typeof props.options = props.disabledOptions
    ? props.options.filter(
        (option: BasicMultiSelectFieldInputOption<P>) =>
          !props.disabledOptions?.find(
            (disabledOption) => disabledOption.value === option.value
          )
      )
    : props.options;

  return (
    <Field {...fieldProps}>
      <Controller
        control={props.control}
        defaultValue={props.defaultValue}
        name={props.name}
        render={({ field: { onChange, ref, value } }) => {
          let returnedValue: any = multiple ? [] : undefined;

          /* ------ STRUCTURE THE VALUES ----- */

          // TODO: clean this part with treating different kind of inputs (arrays, objects with arrays, etc...), and ask external logic to adapt to
          // this component data structure, and not creating exceptions in this components/

          if (value) {
            // If we are in multiples mode and the value is already an array, keep the user provided order
            if (Array.isArray(value)) {
              returnedValue =
                value
                  .map((v: any) =>
                    props.options.find((p) => compareValue(v, p.value))
                  )
                  .filter((v: any) => Boolean(v)) ?? [];
            } else {
              // If it's multiples but the value is not an array (eg, an object with multiples arrays like "{ orgs: string[]; teams: string[]; users: string[] }" for "subscribers" input,
              // base ourselves on options to get the final returned value.
              // Mots of the time it's joined with a custom compareValue fonction
              returnedValue = props.options.filter((p) =>
                compareValue(value, p.value)
              );
            }

            /* if value should be unique, only take the first one */
            if (!multiple) {
              returnedValue = returnedValue[0];
            }
          } else if (value === null) {
            // this condition is added to handle the case when the user clicks on the X (clear) over the badge of selected input -mobile view-, so it doesn't créash the app
            returnedValue = { label: value, value: value };
          }
          /* ------ DEFINE SELECT PROPS ----- */

          const selectProps: SelectProps<P> = {
            autoFocus: props.autoFocus,
            closeMenuOnSelect: !multiple,
            components: {
              /** ClearIndicator: custom button to clear the field (a bin) */
              ClearIndicator: clearButton ? ClearIndicator : undefined,
              /** Custom ctronol zone of the Select */
              Control,
              /** DropdownIndicator: custom top down arrows on the right of the select  */
              DropdownIndicator:
                dropdownButton && !props.disabled
                  ? DropdownIndicator
                  : undefined,
              /** Don't display a separator in the input between the content and the right indicator arrows */
              IndicatorSeparator: null,
              /** Custom dropdown indicator wrapper */
              IndicatorsContainer,
              /** MultiValue: custom badges inside the input while on multiple select mode */
              MultiValue,
              /** Input: custom text inside the input while on multiple select mode */
              // Input,
              /** Option: custom options displayed in the dropdown list to select */
              Option: (props) => {
                return (
                  <Box
                    css={{
                      "& > div > div": { padding: "$xxsmall $small" },
                    }}
                  >
                    <SelectOption {...props} />
                  </Box>
                );
              },
              ValueContainer: CustomValueContainer,
            },
            defaultInputValue: props.defaultInputValue,
            /** Filtering part of the content of the Select */
            filterOption: props.filterOption,
            /** isDisabled: puts the select in grey */
            isDisabled: props.disabled,
            /** isLoading: displays a loading indicatopr on the right when set to true */
            isLoading: props.isLoading ?? isLoading,
            /** isMulti enable the Select to select multiple options */
            isMulti: multiple,
            /** Placement of the dropdown list in html (important for accessibility purpose, especially on mobile) */
            menuPortalTarget: document.body,
            onBlur: props.onSelectBlur,
            onChange: (val) => {
              const returnedChanged = props.onSelect
                ? props.onSelect(
                    val as Options<BasicMultiSelectFieldInputOption<P>>
                  )
                : onChange(
                    multiple
                      ? (
                          val as Options<BasicMultiSelectFieldInputOption<P>>
                        ).map((c) => c.value)
                      : (val as BasicMultiSelectFieldInputOption<P>)?.value || {
                          name: "", // hack to set a void element when
                        }
                  );

              return returnedChanged;
            },
            onInputChange: (v: string) => {
              props.onInputChange?.(v);
              return v;
            },
            /* not necessary to open menu on focus on mobile, 
            especially since it breaks menu behavior on mobile */
            openMenuOnFocus: breakpoint === "mobile" ? false : openMenuOnFocus,
            /** List of the options selectable in the Select */
            options: props.options,
            /** Placeholder: text displayed in the select if no value is present */
            placeholder:
              placeholder ??
              (props.onCreateOption
                ? t(
                    "components.BasicMultiSelectFieldInput.creatableSelectPlaceholder",
                    "Select or type to create..."
                  )
                : t(
                    "components.BasicMultiSelectFieldInput.selectPlaceholder",
                    "Select..."
                  )),
            /** Custom styles in addition to "components" to customise the UI of the Select */
            styles: {
              input: (base) => ({
                ...base,
                "& > input:focus": {
                  boxShadow: "none !important",
                },
                cursor: "text",
              }),
              menu: (base) => ({
                ...base,
              }),
              menuPortal: (base) => ({
                ...base,
                pointerEvents: "auto",
                zIndex: 9990,
              }),
              valueContainer: (base) => ({
                ...base,
                backgroundColor: props.disabled ? "lightGrey" : undefined,
                gap: "4px",
                overflow: "auto",
              }),
            },
            /** Select the currently focused option when the user presses tab */
            tabSelectsValue: props.tabSelectsValue,
            /** Value given to the Select */
            value: returnedValue,
          };

          // TODO: verify that create component is really necessary and singular
          /* props for specific Create component for Select. the Create component helps us also to create elements, and it works a litle bit differently than standard select */
          const additionalCreatableSelectProps = {
            formatCreateLabel: (inputValue: string) => {
              if (props.formatCreateLabel) {
                return props.formatCreateLabel(inputValue);
              } else {
                return t(
                  "components.BasicMultiSelectFieldInput.createLabel",
                  `Create: "{{value}}"`,
                  { value: inputValue }
                );
              }
            },
            onCreateOption: props.onCreateOption ? handleCreate : undefined,
          };

          /* ------ CHOOSE WHICH SELECT TO DISPLAY FUNCTION OF DISPLAY (MOBILE SELECT IS FULLSCREEN AND HAS A DIFFERENT BEHAVIOR) ----- */

          if (breakpoint === "mobile") {
            /** We use a custom full screen select on mobile for accessibility purpose */
            return (
              <MobileCustomSelect
                /** pass the right component function of if it's a creatble or a simple select */ // TODO: simplify this, creatble is normally the same component
                selectComponent={
                  props.onCreateOption ? CreatableSelect : Select
                }
                controlled={props.controlled}
                label={props.label}
                {...selectProps}
                {...additionalCreatableSelectProps}
                ref={ref}
                css={props.cssMobile}
              />
            );
          } else {
            if (props.onCreateOption) {
              return (
                <CreatableSelect
                  ref={ref}
                  {...selectProps}
                  {...additionalCreatableSelectProps}
                  isClearable={isClearable}
                />
              );
            } else {
              /** This is the main component used for Select, the other cases are much less used in the stack */
              return <Select ref={ref} {...selectProps} />;
            }
          }
        }}
      />
    </Field>
  );
}

// This is a trick to have forwardRef to avoid React runtime error
// And in the same time having Typescript generics into component props
// To keep strongly typed components.
// See: https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
const CastedBasicMultiSelectFieldInputRef = React.forwardRef(
  BasicMultiSelectFieldInputRef
) as <T>(
  props: BasicMultiSelectFieldInputProps<T> & {
    ref: React.ForwardedRef<RefCallBack>;
  }
) => JSX.Element;

const BasicMultiSelectFieldInput = CastedBasicMultiSelectFieldInputRef;

export type {
  BasicMultiSelectFieldInputOption,
  BasicMultiSelectFieldInputProps,
  FilterOption,
  SelectProps,
};
export { BasicMultiSelectFieldInput, BasicMultiSelectFieldInputOptionType };
