import { z } from "zod";
import { color } from "../../validation/color";

const validationSchema = z.object({
  color,
  name: z.string().min(1),
  orgId: z.string().uuid(),
});

export { validationSchema };
