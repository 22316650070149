import type * as Types from '../../../../../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrgProfileContainerQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type OrgProfileContainerQuery = { __typename: 'query_root', orgs_by_pk?: { id: string, name: string, phone?: string | null | undefined, legal_number?: string | null | undefined, description?: string | null | undefined, background?: string | null | undefined, org_avatar?: { public_url: string } | null | undefined, address?: { street?: string | null | undefined, postal_code?: string | null | undefined, city?: string | null | undefined, country?: string | null | undefined, lat?: number | null | undefined, lng?: number | null | undefined } | null | undefined } | null | undefined };

export type ProjectInfosQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  projectId: Types.Scalars['uuid'];
}>;


export type ProjectInfosQuery = { projects_by_pk?: { name: string, members: Array<{ user: { id: string, full_name?: string | null | undefined, user_avatar?: { public_url: string } | null | undefined } }> } | null | undefined };


export const OrgProfileContainerDocument = gql`
    query OrgProfileContainer($orgId: uuid!) {
  __typename
  orgs_by_pk(id: $orgId) {
    id
    name
    phone
    legal_number
    description
    org_avatar {
      public_url
    }
    background
    address {
      street
      postal_code
      city
      country
      lat
      lng
    }
  }
}
    `;

export function useOrgProfileContainerQuery(options: Omit<Urql.UseQueryArgs<OrgProfileContainerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<OrgProfileContainerQuery>({ query: OrgProfileContainerDocument, ...options });
};
export const ProjectInfosDocument = gql`
    query ProjectInfos($orgId: uuid!, $projectId: uuid!) {
  projects_by_pk(id: $projectId) {
    name
    members(where: {user: {active_org: {org: {id: {_eq: $orgId}}}}}) {
      user {
        id
        user_avatar {
          public_url
        }
        full_name
      }
    }
  }
}
    `;

export function useProjectInfosQuery(options: Omit<Urql.UseQueryArgs<ProjectInfosQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProjectInfosQuery>({ query: ProjectInfosDocument, ...options });
};