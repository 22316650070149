import * as React from "react";
import { createComponentHook } from "~/types";
import { useNavigationDrawerStore } from "../../utils/useNavigationDrawerStore";
import { ProjectListContentByGroup } from "./components/ProjectListContentByGroup";
import { ProjectListHeaderByGroup } from "./components/ProjectListHeaderByGroup";
import type { ProjectNavigationDrawer } from "./ProjectListMenu";

type ProjectListByGroupProps = {
  projectListByGroup: ProjectListByGroup[];
  activeProjectId: string | undefined;
};

type ProjectListByGroup = {
  id: string;
  projects: ProjectNavigationDrawer[];
  color: string;
  name: string;
  avatar?: string | null | undefined;
  project_group_avatar?: {
    public_url: string;
  };
};

const useProjectListByGroup = createComponentHook(() => {
  const { groupID, setGroupID } = useNavigationDrawerStore();

  const handleIndexContent = React.useCallback(
    (groupID: string | null) => {
      setGroupID(groupID);
    },
    [setGroupID]
  );

  return {
    actions: { groupID, handleIndexContent },
    state: { groupID },
  };
});

function ProjectListByGroupMenu(props: ProjectListByGroupProps) {
  const { actions, state } = useProjectListByGroup();

  return (
    <>
      {state.groupID === null && (
        <ProjectListHeaderByGroup
          projectListByGroup={props.projectListByGroup}
          onClick={actions.handleIndexContent}
        />
      )}

      {props.projectListByGroup.map((projectList, index) =>
        state.groupID === projectList.id ? (
          <ProjectListContentByGroup
            projectListByGroup={projectList}
            key={index}
            activeProjectId={props.activeProjectId}
            goBack={() => actions.handleIndexContent(null)}
          />
        ) : null
      )}
    </>
  );
}
export type { ProjectListByGroup };
export { ProjectListByGroupMenu };
