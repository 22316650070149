import React from "react";
import type { VirtualItem } from "react-virtual";
import { NavListItem } from "~/design-system/Navigation/NavListItem";
import type { ProjectListMenuProps } from "../ProjectListMenu";
import { ProjectItem } from "./ProjectItem/ProjectItem";

const VirtualRow = React.memo(
  ({
    activeProjectId,
    row,
    virtualRow,
  }: {
    virtualRow: VirtualItem;
    row: ProjectListMenuProps["projects"][number];
    activeProjectId?: string;
  }) => (
    <NavListItem
      key={row.id}
      ref={virtualRow.measureRef}
      css={{
        height: `${virtualRow.size}px`,
        left: 0,
        position: "absolute",
        top: 0,
        transform: `translateY(${virtualRow.start}px)`,
        width: "100%",
      }}
    >
      <ProjectItem data={row} active={row.id === activeProjectId} />
    </NavListItem>
  )
);

export { VirtualRow };
