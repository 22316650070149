import { PlusSmIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Avatar } from "~/design-system/Avatar";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import type { ControlledDrawerProps } from "~/design-system/Drawer";
import { DrawerTrigger, useDrawerContext } from "~/design-system/Drawer";
import { Text } from "~/design-system/Text";
import type { CreateOrgDrawerProps } from "~/screens/App/components/CreateOrgDrawer/CreateOrgDrawer";
import { CreateOrgDrawer } from "~/screens/App/components/CreateOrgDrawer/CreateOrgDrawer";
import type { NavigationDrawerHeaderProps } from "../NavigationDrawerHeader";

const CreateOrgButton = (props: {
  large: boolean;
  controlledDrawer: Pick<ControlledDrawerProps, "open" | "setOpen">;
}) => {
  const navigate = useNavigate();
  const handleCreateOrgSuccess: CreateOrgDrawerProps["onSuccess"] = (orgId) => {
    navigate(`/org/${orgId}/edit`);
  };

  return (
    <CreateOrgDrawer
      onSuccess={handleCreateOrgSuccess}
      controlledDrawer={{
        open: props.controlledDrawer.open,
        setOpen: props.controlledDrawer.setOpen,
      }}
    >
      <DrawerTrigger asChild>
        <Button
          dataIntercomTarget="navigation-drawer-create-org"
          circular={true}
          tone="brandAccent"
          size={props.large ? "large" : "standard"}
          css={{ flexShrink: 0 }}
          iconLabel="create"
        >
          <PlusSmIcon />
        </Button>
      </DrawerTrigger>
    </CreateOrgDrawer>
  );
};

const OrgAvatar = (props: {
  activeOrg: NavigationDrawerHeaderProps["activeOrg"];
}) => {
  return (
    <Avatar
      alt={props.activeOrg?.name ?? ""}
      src={props.activeOrg?.org_avatar?.public_url ?? undefined}
      shape="rounded"
      size="large"
    />
  );
};

const HeaderOrg = (props: {
  activeOrg: NavigationDrawerHeaderProps["activeOrg"];
}) => {
  const { setOpen } = useDrawerContext();
  const { t } = useTranslation();
  const createOrgSentence = t(
    "screens.NavigationDrawer.NavigationDrawerHeader.HeaderOrg.CreateOrg",
    "Create an organization"
  );
  const [openCreateOrgDrawer, setOpenCreateOrgDrawer] = React.useState(false);
  return (
    <Box
      display="flex"
      alignItems="center"
      css={{
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          cursor: "pointer",
        },
        height: "4.75rem",
        maxWidth: "100%",
        minWidth: "100%",
        paddingRight: "0.75rem",
      }}
      padding="small"
      onClick={() => {
        if (!props.activeOrg) {
          setOpenCreateOrgDrawer(true);
        }
      }}
    >
      {props.activeOrg ? (
        <Link
          to={`/org/${props.activeOrg.id}/`}
          onClick={() => {
            setOpen(false);
          }}
          style={{ height: "3.125rem" }}
        >
          <OrgAvatar activeOrg={props.activeOrg} />
        </Link>
      ) : (
        <CreateOrgButton
          large={false}
          controlledDrawer={{
            open: openCreateOrgDrawer,
            setOpen: setOpenCreateOrgDrawer,
          }}
        />
      )}
      {props.activeOrg ? (
        <Box display={"flex"} width="full" justifyContent={"spaceBetween"}>
          <Button
            as={Link}
            link
            to={`/org/${props.activeOrg.id}/`}
            onClick={() => {
              setOpen(false);
            }}
            css={{
              minHeight: "fit-content",
              width: "100%",
            }}
            variant="text"
            dataIntercomTarget="navigation-drawer-active-org-name"
          >
            <Text
              css={{ paddingLeft: "1rem", width: "100%" }}
              align="left"
              tone="white"
            >
              {props.activeOrg?.name}
            </Text>
          </Button>
        </Box>
      ) : (
        <>
          <Text
            align="left"
            size="large"
            truncate={true}
            css={{ paddingLeft: "1rem" }}
            tone={props.activeOrg ? "white" : "brandAccent"}
          >
            {createOrgSentence}
          </Text>
        </>
      )}
    </Box>
  );
};

export { HeaderOrg };
