import { Color } from "@clovis/server/src/app/config/colors/colors";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "~/config/user/UserProvider";
import type { BadgeProps } from "~/design-system/Badge";
import { BadgesCluster } from "~/design-system/BadgesCluster";
import { useBreakpoint } from "~/design-system/hooks";
import {
  OrgSummaryProjectCategoryBadgeButton,
  ProjectCategoryBadge,
} from "~/screens/App/components/Badges";
import type { ProjectListMenuProps } from "~/screens/App/components/NavigationDrawer/components/ProjectListMenu/ProjectListMenu";
import { createComponentHook } from "~/types";
import type { ProjectsMaps_ProjectsFragment } from "../../../../ProjectsMapScreen/ProjectsMapScreen.graphql";
import type { CategoryType } from "../../../types";
import { CategoryBadge } from "./CategoryBadge";
import { useProjectOrgData_ProjectCategoriesQuery } from "./ProjectOrgCategories.graphql";

type ProjectOrgCategoriesProps = {
  projectId: string;
  badgeSize?: BadgeProps["size"];
  onClick?: (e: React.MouseEvent) => void;
  disableOnClick?: boolean;
  maxNumberOfBadges?: number;
  isDemo?: boolean;
  data?:
    | ProjectListMenuProps["projects"][number]
    | ProjectsMaps_ProjectsFragment
    | undefined;
  shouldPauseOrgQuery?: boolean;
};

const MAX_BADGES_BY_VIEW: Record<"desktop" | "mobile" | "tablet", number> = {
  desktop: 2,
  mobile: 1,
  tablet: 1,
};

const useProjectOrgCategories = createComponentHook(
  (props: ProjectOrgCategoriesProps) => {
    const { t } = useTranslation();
    const { breakpoint } = useBreakpoint();
    const { user } = useUser();

    const currentUserOrgId = React.useMemo(
      () => user.active_org?.org.id,
      [user.active_org?.org.id]
    );

    const shouldPauseQuery = React.useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return !currentUserOrgId || props.isDemo || Boolean(props.data);
    }, [currentUserOrgId, props.isDemo, props.data]);

    const queryContext = React.useMemo(
      () => ({ additionalTypenames: ["orgs"] }),
      []
    );

    const [{ data: projectData }] = useProjectOrgData_ProjectCategoriesQuery({
      context: queryContext,
      pause: shouldPauseQuery,
      variables: { orgId: currentUserOrgId!, projectId: props.projectId },
    });

    const mapToCategory = (projectCategory: {
      color: string;
      id: string;
      name: string;
    }): CategoryType => ({
      color: projectCategory.color as Color,
      id: projectCategory.id,
      name: projectCategory.name,
    });

    const categories = React.useMemo(() => {
      if (props.isDemo) {
        return [
          {
            color: Color.Amber,
            id: "demo",
            name: t(
              "screens.ProjectsListScreen.ProjectOrgCategories.demoLabel",
              "Demo project"
            ),
          },
        ];
      }

      if (!currentUserOrgId) {
        return [];
      }

      const categoryData = props.data
        ? props.data?.org_project_summary?.[0]?.project_categories
        : projectData?.projects_by_pk?.org_project_summary?.[0]
            ?.project_categories;

      return (
        categoryData?.map(({ project_category }) =>
          mapToCategory(project_category)
        ) ?? []
      );
    }, [
      props.isDemo,
      props.data,
      currentUserOrgId,
      projectData?.projects_by_pk?.org_project_summary,
      t,
    ]);

    const orgProjectSummaryId = React.useMemo(() => {
      const summary = props?.data?.org_project_summary?.[0];
      if (summary && "id" in summary && !props.shouldPauseOrgQuery) {
        return summary.id;
      }
      return undefined;
    }, [props.data, props.shouldPauseOrgQuery]);

    return {
      state: {
        breakpoint,
        categories,
        orgId: currentUserOrgId,
        orgProjectSummaryId,
      },
    };
  }
);

const ProjectOrgCategories = React.memo(function ProjectOrgCategories(
  props: ProjectOrgCategoriesProps
) {
  const { state } = useProjectOrgCategories(props);

  if (state.categories.length === 0) return null;

  return (
    <BadgesCluster
      max={props.maxNumberOfBadges ?? MAX_BADGES_BY_VIEW[state.breakpoint]}
      size={props.badgeSize}
      customPlusBadge={
        state.orgId && !props.disableOnClick && state.orgProjectSummaryId ? (
          <OrgSummaryProjectCategoryBadgeButton
            size={props.badgeSize}
            color={Color.Gray}
            orgId={state.orgId}
            onClick={props.onClick}
            orgProjectSummaryId={state.orgProjectSummaryId}
            shouldPauseOrgQuery={props.shouldPauseOrgQuery}
          >
            {"anythig"}
          </OrgSummaryProjectCategoryBadgeButton>
        ) : (
          <ProjectCategoryBadge hideIcon={true} size={props.badgeSize}>
            {"anythig"}
          </ProjectCategoryBadge>
        )
      }
    >
      {state.categories.map((category) => (
        <CategoryBadge
          key={`${category.name}-${category.id}`}
          category={category}
          orgId={state.orgId}
          disableOnClick={props.disableOnClick}
          orgProjectSummaryId={state.orgProjectSummaryId}
          size={props.badgeSize}
          onClick={props.onClick}
          shouldPauseOrgQuery={props.shouldPauseOrgQuery}
        />
      ))}
    </BadgesCluster>
  );
});

export { ProjectOrgCategories };
