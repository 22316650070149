import React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { OrgSummaryProjectCategoryBadgeButton } from "~/screens/App/components/Badges";
import { ProjectCategoryBadge } from "~/screens/App/components/Badges/ProjectCategoryBadge";
import type { CategoryType } from "../../../types";

const CategoryBadge = React.memo(
  ({
    category,
    disableOnClick,
    onClick,
    orgId,
    orgProjectSummaryId,
    shouldPauseOrgQuery,
    size,
  }: {
    category: CategoryType;
    orgId?: string;
    disableOnClick?: boolean;
    orgProjectSummaryId?: string;
    size?: BadgeProps["size"];
    onClick?: (e: React.MouseEvent) => void;
    shouldPauseOrgQuery?: boolean;
  }) => {
    if (orgId && !disableOnClick && orgProjectSummaryId) {
      return (
        <OrgSummaryProjectCategoryBadgeButton
          size={size}
          color={category.color}
          orgId={orgId}
          onClick={onClick}
          orgProjectSummaryId={orgProjectSummaryId}
          shouldPauseOrgQuery={shouldPauseOrgQuery}
        >
          {category.name}
        </OrgSummaryProjectCategoryBadgeButton>
      );
    }

    return (
      <ProjectCategoryBadge size={size} color={category.color}>
        {category.name}
      </ProjectCategoryBadge>
    );
  }
);

export { CategoryBadge };
