import * as React from "react";
import { useTranslation } from "react-i18next";
import { useVirtual } from "react-virtual";
import { Box } from "~/design-system/Box";
import { Nav, NavList } from "~/design-system/Navigation";
import { Placeholder } from "~/design-system/Placeholder/Placeholder";
import type { SortType } from "~/screens/App/screens/Org/components/OrgMembersSortDropdown/OrgMembersSort.store";
import type {
  GroupProjectList,
  OrgSettings,
  UserProject,
} from "../../NavigationDrawer";
import type { NavigationDrawer_UserQuery } from "../../NavigationDrawer.graphql";
import { NoProject } from "./components/NoProject";
import { PROJECT_ITEM_HEIGHT } from "./components/ProjectItem/ProjectItem";
import { ProjectListHeader } from "./components/ProjectListHeader";
import { VirtualRow } from "./components/VirtualRow";
import type { ProjectListByGroup } from "./ProjectListByGroup";
import { ProjectListByGroupMenu } from "./ProjectListByGroup";

type ProjectListMenuProps = {
  projects: UserProject[];
  activeProjectId?: string;
  closeDrawer: () => void;
  groupProjectList?: GroupProjectList;
  orgSettings?: OrgSettings;
};

type UserNavigationDrawer = NonNullable<NavigationDrawer_UserQuery["user"]>;
type ProjectNavigationDrawer = NonNullable<
  UserNavigationDrawer["projects"]
>[0]["project"];
type ProjectSummary = NonNullable<
  ProjectNavigationDrawer["org_project_summary"]
>[0];

const useProjectListMenu = (props: ProjectListMenuProps) => {
  const parentRef = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState<string>();
  const [searchMode, setSearchMode] = React.useState<boolean>(false);
  const [sortType, setSortType] = React.useState<SortType>("asc");

  const handleChangeSortType = React.useCallback(
    (newSortType: SortType) => {
      setSortType(newSortType);
    },
    [setSortType]
  );

  const filteredProjects = props.projects
    .filter((p) => {
      if (!p.org_project_summary?.length) {
        // if the user is not a member of an org, we can't filter by is_archived
        // so we need to show all projects
        return true;
      }
      return (
        !p.org_project_summary?.[0]?.is_archived &&
        !p.org_project_summary?.[0]?.is_project_template
      );
    })
    .filter((p) =>
      searchQuery
        ? p.name.toLowerCase().includes(searchQuery.toLowerCase())
        : true
    );
  const rowVirtualizer = useVirtual({
    estimateSize: React.useCallback(() => PROJECT_ITEM_HEIGHT, []),
    parentRef,
    size: filteredProjects.length,
  });
  const { t } = useTranslation();

  const groupProjectList = React.useMemo(() => {
    return props.groupProjectList ?? [];
  }, [props.groupProjectList]);

  const projectsListByGroup = React.useMemo(() => {
    return !groupProjectList || groupProjectList.length === 0
      ? []
      : groupProjectList.map((category, index) => {
          const rowFilteredByGroup = filteredProjects.filter(
            (projectItem: ProjectNavigationDrawer) =>
              projectItem.org_project_summary?.some((ops: ProjectSummary) => {
                return ops.project_groups.some(
                  (pc: {
                    project_group: { name: string | null | undefined };
                  }) => pc.project_group.name === category.name
                );
              })
          );
          return {
            ...category,
            id: `${category.name}_${index}`,
            projects: rowFilteredByGroup,
          };
        });
  }, [filteredProjects, groupProjectList]);

  const projectListWithOutGroup = React.useMemo(() => {
    return [
      {
        avatar: undefined,
        color: "brown",
        id: "without-group",
        name: t(
          "NavigarionDrawer.ProjectListMenu.WithOutGroup",
          "Projects without groups"
        ),
        projects: filteredProjects.filter(
          (projectItem: ProjectNavigationDrawer) =>
            !projectItem.org_project_summary ||
            projectItem.org_project_summary?.length === 0 ||
            projectItem.org_project_summary.some(
              (ops: ProjectSummary) =>
                !ops.project_groups ||
                ops.project_groups.length === 0 ||
                ops.project_groups.some(
                  (pc: {
                    project_group: { name: string | null | undefined };
                  }) =>
                    pc.project_group.name === undefined ||
                    pc.project_group.name === null
                )
            )
        ),
      },
    ];
  }, [filteredProjects, t]);

  const showMenuByGroup = props.orgSettings?.show_menu_by_group ?? false;

  const sortedProjects = React.useMemo(() => {
    return filteredProjects.sort((a, b) => {
      if (sortType === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
  }, [filteredProjects, sortType]);

  const sortedListByGroup = React.useMemo(() => {
    const compareNames = (nameA: string, nameB: string, ascending: boolean) => {
      return ascending
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    };

    const sortProjects = (projects: ProjectNavigationDrawer[]) => {
      return [...projects].sort((a, b) =>
        compareNames(a.name, b.name, sortType === "asc")
      );
    };

    const sortGroups = (groups: typeof projectsListByGroup) => {
      return [...groups].sort((a, b) =>
        compareNames(a.name, b.name, sortType === "asc")
      );
    };

    const allGroups = [...projectsListByGroup, ...projectListWithOutGroup];

    const sortedGroups = sortGroups(
      allGroups.map((group) => ({
        ...group,
        projects: sortProjects(group.projects),
      }))
    );

    return sortedGroups;
  }, [projectsListByGroup, projectListWithOutGroup, sortType]);

  return {
    actions: {
      handleChangeSortType,
      setSearchMode,
      setSearchQuery,
    },
    refs: {
      parentRef,
    },
    state: {
      groupProjectList,
      projects: sortedProjects,
      projectsListByGroup: sortedListByGroup as ProjectListByGroup[],
      rowVirtualizer,
      searchMode,
      searchQuery,
      showMenuByGroup,
      sortType,
    },
  };
};

const ProjectListMenu = React.memo(function ProjectListMenu(
  props: ProjectListMenuProps
) {
  const { actions, refs, state } = useProjectListMenu(props);

  const MemoizedNavList = React.useCallback(
    () => (
      <NavList
        css={{
          color: "white",
          height: `${state.rowVirtualizer.totalSize}px`,
          position: "relative",
          width: "100%",
        }}
      >
        {state.rowVirtualizer.virtualItems.map((virtualRow) => (
          <VirtualRow
            key={virtualRow.index}
            virtualRow={virtualRow}
            row={state.projects[virtualRow.index]}
            activeProjectId={props.activeProjectId}
          />
        ))}
      </NavList>
    ),
    [state.rowVirtualizer, state.projects, props.activeProjectId]
  );

  return (
    <Box display="flex" flexDirection="column" flexGrow="1">
      <ProjectListHeader
        closeDrawer={props.closeDrawer}
        setSearchQuery={actions.setSearchQuery}
        searchQuery={state.searchQuery}
        setSearchMode={actions.setSearchMode}
        sortType={state.sortType}
        handleChangeSortType={actions.handleChangeSortType}
      />
      {state.projects && state.projects.length > 0 ? (
        <Nav
          ref={refs.parentRef}
          css={{
            flexGrow: 1,
            overflowY: "auto",
          }}
          label="Projects List"
        >
          {(!state.showMenuByGroup || state.searchMode) && MemoizedNavList()}
          {state.showMenuByGroup && !state.searchMode && (
            <ProjectListByGroupMenu
              projectListByGroup={state.projectsListByGroup}
              activeProjectId={props.activeProjectId}
            />
          )}
          {/*Add a placeholder help the user to see easily the last item*/}
          <Placeholder background="body" height="xxlarge" width="full" />
        </Nav>
      ) : (
        <NoProject closeDrawer={props.closeDrawer} />
      )}
    </Box>
  );
});

export type { ProjectListMenuProps, ProjectNavigationDrawer };
export { ProjectListMenu };
