import "@twicpics/components/style.css";
import { installTwicPics, TwicImg } from "@twicpics/components/react";
import * as React from "react";
import { env } from "~/config/env";
import type { Attributes } from "./types";
import {
  computeRatio,
  isLocalDevelopmentImage,
  removeQueryParamsFromPresignedUrl,
} from "./utils";

const TWICPICS_BASE_URL = `https://${env.VITE_TWICPICS_DOMAIN}`;
const LOCAL_BLURRY_PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==";

function initTwicPics() {
  console.info("Twicpics Initialized");
  installTwicPics({
    domain: TWICPICS_BASE_URL,
  });
}

type TwicPicsProps = Attributes;
type BaseImgHtmlProps = React.ImgHTMLAttributes<HTMLImageElement>;

type ImageProps = Partial<TwicPicsProps> &
  React.ImgHTMLAttributes<HTMLImageElement>;

function Image(props: ImageProps) {
  const { className, style, ...rest } = props;

  const cleanSrc = removeQueryParamsFromPresignedUrl(props.src);
  const computedRatio = computeRatio(
    Number(props.height),
    props.ratio,
    Number(props.width)
  );

  /* Because twicpics needs to access our local files for dev, we don't use twicpics to see images under dev */
  if (!props.src || isLocalDevelopmentImage(props.src)) {
    return (
      <div className={className} style={style}>
        {/* Mimic TwicPic image final render to keep consistencies in our class/styles */}
        <div
          className="twic-w twic-t-fade"
          style={{
            backgroundImage:
              props.mode === "cover"
                ? `url(${LOCAL_BLURRY_PLACEHOLDER})`
                : undefined,
            backgroundPosition: "center center",
            backgroundSize: props.mode ?? "cover",
            height: "100%",
            paddingTop: `${(
              (computedRatio[1] * 100) /
              computedRatio[0]
            ).toFixed(10)}%`,
            width: "100%",
          }}
        >
          <img
            className="twic-done"
            style={{
              objectFit: props.mode ?? "cover",
              objectPosition: "center center",
            }}
            src={props.src} // We don't use `cleanSrc` here because we need presigned url
            loading={props.loading}
          />
          {props.children}
        </div>
      </div>
    );
  }
  const twicPicsProps: BaseImgHtmlProps & TwicPicsProps = {
    ...rest,
    mode: props.mode ?? "cover",
    src: `${env.VITE_TWICPICS_CLOVIS_CDN}${cleanSrc}`,
    transition: props.transition ?? true,
  };
  return (
    <div className={className} style={style}>
      <TwicImg {...twicPicsProps} />
      {props.children}
    </div>
  );
}

export type { ImageProps, TwicPicsProps };
export { Image, initTwicPics };
