import type { Color } from "@clovis/server/src/app/config/colors/colors";
import React from "react";
import type { OptionProps } from "react-select";
import { styled } from "~/config/stitches";
import { Badge } from "~/design-system/Badge";
import { Text } from "~/design-system/Text";
import {
  OrgBadge,
  ProjectBadge,
  ProjectCategoryBadge,
  ProjectLabelBadge,
  TeamBadge,
  UserBadge,
} from "../../Badges";
import { TaskBadge } from "../../Badges/TaskBadge";
import type { BasicMultiSelectFieldInputOption } from "../BasicMultiSelectFieldInput";
import { BasicMultiSelectFieldInputOptionType } from "../BasicMultiSelectFieldInput";

function Option(props: { data: BasicMultiSelectFieldInputOption }) {
  switch (props.data.type) {
    case BasicMultiSelectFieldInputOptionType.TEAM:
      return (
        <TeamBadge color={props.data.color as Color} size="large">
          {props.data.label}
        </TeamBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.PROJECT_LABEL:
      return (
        <ProjectLabelBadge color={props.data.color as Color} size="large">
          {props.data.label}
        </ProjectLabelBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.PROJECT_CATEGORY:
      return (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <ProjectCategoryBadge color={props.data.color as Color} size="large">
          {props.data.label}
        </ProjectCategoryBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.ORG:
      return (
        <OrgBadge avatar={props.data.avatar} size="large">
          {props.data.label}
        </OrgBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.TEMPORARY_ORG:
      return (
        <OrgBadge avatar={props.data.avatar} size="large">
          {props.data.label}
        </OrgBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.PROJECT:
      return (
        <ProjectBadge avatar={props.data.avatar} size="large">
          {props.data.label}
        </ProjectBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.USER:
      return (
        <UserBadge avatar={props.data.avatar} size="large">
          {props.data.label}
        </UserBadge>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.AUTOCOMPLETE:
      return (
        <Text size="standard" truncate>
          {props.data.label}
        </Text>
      );
      break;
    case BasicMultiSelectFieldInputOptionType.TASK:
      return (
        <TaskBadge size="large" task={props.data.task}>
          {props.data.label}
        </TaskBadge>
      );
      break;
    default:
      return <Badge size="large">{props.data.label}</Badge>;
  }
}

const StyledRow = styled("div", {
  cursor: "pointer",
  variants: {
    focused: {
      true: {
        backgroundColor: "$neutral200",
      },
    },
    selected: {
      true: {
        backgroundColor: "$neutral300",
      },
    },
  },
});

function SelectOption(
  props: OptionProps<BasicMultiSelectFieldInputOption<any>>
) {
  return (
    <div ref={props.innerRef} {...props.innerProps}>
      <StyledRow focused={props.isFocused} selected={props.isSelected}>
        <Option data={props.data} />
      </StyledRow>
    </div>
  );
}

export { Option, SelectOption };
