import { ExternalLinkIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Box } from "~/design-system/Box";
import { Button } from "~/design-system/Button";
import { DrawerClose } from "~/design-system/Drawer";
import { Heading } from "~/design-system/Heading";
import { Stack } from "~/design-system/Stack";
import { Text } from "~/design-system/Text";
import { CloseButton } from "~/screens/App/components/CloseButton";
import type { GetOrgProjectSummaryIdQuery } from "../UpdateOrgProjectSummaryDrawerContent.graphql";

function DrawerHeader(props: {
  project?: NonNullable<
    GetOrgProjectSummaryIdQuery["org_project_summary_by_pk"]
  >["project"];
}) {
  const { t } = useTranslation();
  const { projectId: projectIdUrl } = useParams() as {
    projectId: string;
  };

  return (
    <Box
      css={{ backgroundColor: "$emerald500", color: "$white" }}
      padding="gutter"
      gap="small"
      display="flex"
      flexDirection="column"
    >
      <Stack space="medium">
        <Box
          display="flex"
          width="full"
          justifyContent="spaceBetween"
          gap="small"
        >
          <Heading level="3">
            {t(
              "screens.UpdateOrgProjectSummaryDrawerContent.DrawerHeader.title",
              "Update your org project data"
            )}
          </Heading>
          <Box>
            <DrawerClose asChild>
              <CloseButton dataIntercomTarget="project-profile-org-project-summary-update-close" />
            </DrawerClose>
          </Box>
        </Box>

        <Text css={{ color: "$white" }}>
          {t(
            "screens.UpdateOrgProjectSummaryDrawerContent.DrawerHeader.message",
            "You can change those information at any time"
          )}
        </Text>
      </Stack>
      {!projectIdUrl && (
        <Box as={Link} to={`/project/${(props.project as { id: string })?.id}`}>
          <Button
            onClick={() => null}
            dataIntercomTarget="open-project"
            variant="ghost"
            color="white"
            size="xsmall"
            leadingIcon={<ExternalLinkIcon />}
          >
            {t(
              "screens.UpdateOrgProjectSummaryDrawerContent.DrawerHeader.OpenProject",
              "Open project "
            )}
            {(props.project as { name: string })?.name}
          </Button>
        </Box>
      )}
    </Box>
  );
}

export { DrawerHeader };
