import * as React from "react";
import type { BadgeProps } from "~/design-system/Badge";
import { Button } from "~/design-system/Button";
import { DrawerTrigger, useDrawerContext } from "~/design-system/Drawer";
import { OrgProjectCategoriesDrawer } from "../../screens/Org/components/OrgProjectCategoriesDrawer/OrgProjectCategoriesDrawer";
import { UpsertOrgProjectSummaryDrawer } from "../../screens/Project/Info/screens/ProjectProfileScreen/components/Profile/components/components/ProjectOrgData/components/UpsertOrgProjectSummaryDrawer/UpsertOrgProjectSummaryDrawer";
import { ProjectCategoryBadge } from "./ProjectCategoryBadge";

type ProjectCategoryBadgeButtonProps = Pick<
  BadgeProps,
  "children" | "color" | "onRemove" | "size" | "truncate"
> & {
  orgId: string;
  onClick?: (e: React.MouseEvent) => void;
  hideIcon?: boolean;
};

type OrgSummaryProjectCategoryBadgeButtonProps =
  ProjectCategoryBadgeButtonProps & {
    orgProjectSummaryId: string;
    shouldPauseOrgQuery?: boolean;
  };

const OrgProjectCategoriesDrawerTrigger = (props: {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  /* we need to use drawer context since preventDefault can be called from the onClick props */
  const { setOpen } = useDrawerContext();
  return (
    <DrawerTrigger
      onClick={(e) => {
        props.onClick?.(e);
        setOpen(true);
      }}
      asChild
    >
      {props.children}
    </DrawerTrigger>
  );
};

const ProjectCategoryBadgeButton = (props: ProjectCategoryBadgeButtonProps) => {
  const { color, ...rest } = props;

  return (
    <OrgProjectCategoriesDrawer orgId={props.orgId}>
      <OrgProjectCategoriesDrawerTrigger onClick={props.onClick}>
        <Button
          variant="text"
          css={{
            display: "inline-flex",
            maxWidth: "100%",
            minHeight: "fit-content",
          }}
          dataIntercomTarget="badges-org-project-category-open"
        >
          <ProjectCategoryBadge clickable color={color} {...rest}>
            {props.children}
          </ProjectCategoryBadge>
        </Button>
      </OrgProjectCategoriesDrawerTrigger>
    </OrgProjectCategoriesDrawer>
  );
};

const OrgSummaryProjectCategoryBadgeButton = (
  props: OrgSummaryProjectCategoryBadgeButtonProps
) => {
  const { color, ...rest } = props;
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <>
      <UpsertOrgProjectSummaryDrawer
        shouldPauseOrgQuery={props.shouldPauseOrgQuery}
        open={openDrawer}
        setOpen={setOpenDrawer}
        update={{
          orgId: props.orgId,
          orgProjectSummaryId: props.orgProjectSummaryId,
        }}
        onSuccess={() => setOpenDrawer(false)}
      />
      <Button
        variant="text"
        css={{
          display: "inline-flex",
          maxWidth: "100%",
          minHeight: "fit-content",
        }}
        onClick={(e) => {
          props.onClick?.(e);
          setOpenDrawer(true);
        }}
        dataIntercomTarget="badges-org-project-category-org-summary-upsert"
      >
        <ProjectCategoryBadge clickable color={color} {...rest}>
          {props.children}
        </ProjectCategoryBadge>
      </Button>
    </>
  );
};

export { OrgSummaryProjectCategoryBadgeButton, ProjectCategoryBadgeButton };
