import type * as Types from '../../../../schema.graphql';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectsMultiSelect_ProjectFragment = { id: string, name: string, project_avatar?: { public_url: string } | null | undefined, org_project_summaries: Array<{ is_project_template: boolean }> };

export type GetProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { projects: Array<{ id: string, name: string, project_avatar?: { public_url: string } | null | undefined, org_project_summaries: Array<{ is_project_template: boolean }> }> };

export type GetOrgProjectTemplatesQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;


export type GetOrgProjectTemplatesQuery = { getOrgProjectTemplates?: { templates?: Array<{ avatar?: string | null | undefined, description?: string | null | undefined, id: string, name: string }> | null | undefined } | null | undefined };

export const ProjectsMultiSelect_ProjectFragmentDoc = gql`
    fragment ProjectsMultiSelect_project on projects {
  id
  name
  project_avatar {
    public_url
  }
  org_project_summaries {
    is_project_template
  }
}
    `;
export const GetProjectsDocument = gql`
    query getProjects {
  projects(
    where: {_not: {org: {id: {_is_null: false}}}, org_project_summary: {is_archived: {_eq: false}}}
  ) {
    ...ProjectsMultiSelect_project
  }
}
    ${ProjectsMultiSelect_ProjectFragmentDoc}`;

export function useGetProjectsQuery(options: Omit<Urql.UseQueryArgs<GetProjectsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetProjectsQuery>({ query: GetProjectsDocument, ...options });
};
export const GetOrgProjectTemplatesDocument = gql`
    query getOrgProjectTemplates($orgId: uuid!) {
  getOrgProjectTemplates(orgId: $orgId) {
    templates {
      avatar
      description
      id
      name
    }
  }
}
    `;

export function useGetOrgProjectTemplatesQuery(options: Omit<Urql.UseQueryArgs<GetOrgProjectTemplatesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetOrgProjectTemplatesQuery>({ query: GetOrgProjectTemplatesDocument, ...options });
};