/* type for is_validated_by task computed field */
const enum Validation {
  None = "none",
  External = "external",
  Org = "org",
  User = "user",
}

/* for now task are closed if either user or someone from his/her org has validate it */
const isTaskClosed = (isValidatedBy?: Validation | null) => {
  return isValidatedBy === Validation.Org || isValidatedBy === Validation.User;
};

interface ProjectData {
  id: string;
  labels: {
    id: string;
    name: string;
  }[];
  members: {
    user: {
      full_name: string;
      id: string;
    };
  }[];
  teams: {
    id: string;
    name: string;
    color: string;
  }[];
}

export { isTaskClosed, Validation };
export type { ProjectData };
